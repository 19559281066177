// 글로벌로 사용할 메시지를 정의합니다.

export default {
  en: {
    global: {
      error: {
        api: {
          100100: {
            summary: 'Authentication Required',
            detail: 'You must be logged in to perform this action. Please log in and try again.',
          },
          100101: {
            summary: 'Missing Access Token',
            detail: 'Your request did not include an access token. Please provide one to proceed.',
          },
          100102: {
            summary: 'Invalid Access Token',
            detail: 'The access token provided is invalid. Please check your token and try again.',
          },
          100103: {
            summary: 'Expired Access Token',
            detail: 'Your access token has expired. Please log in again to renew your session.',
          },
          100110: {
            summary: 'Invalid Authentication Code',
            detail:
              'The authentication code provided is invalid. Please check the code and try again.',
          },
          100200: {
            summary: 'Permission Denied',
            detail:
              'You do not have permission to do this action. Refresh the page and try again. If the issue persists, contact support.',
          },
          100201: {
            summary: 'Signup Required',
            detail: 'You must sign up to use this API. Please create an account to proceed.',
          },
          100202: {
            summary: 'Project Access Denied',
            detail:
              'You do not have access to this project. Please check your permissions or contact the project owner.',
          },
          100204: {
            summary: 'Archived Project',
            detail:
              'This project is archived and cannot be modified. Please contact support for further assistance.',
          },
          100205: {
            summary: 'Too Many Saves per File',
            detail: 'Too many save requests per file, please try again in a few seconds.',
          },
          100300: {
            summary: 'Invalid Argument',
            detail:
              'One or more arguments in your request are invalid. Please check your request and try again.',
          },
          100301: {
            summary: 'Invalid LaTeX Template',
            detail:
              'There is a syntax error in your LaTeX template. Please correct it and try again. Contact us for assistance if the issue persists.',
          },
          100302: {
            summary: 'Invalid Editor State',
            detail:
              'The editor state syntax is incorrect. Please review and correct it. Contact us for assistance if the issue persists.',
          },
          100303: {
            summary: 'Invalid Project or User IDs',
            detail: 'Please ensure you provide valid non-empty user or project IDs.',
          },
          100304: {
            summary: 'Empty Project Name',
            detail: 'The project name cannot be empty. Please provide a valid name and try again.',
          },
          100305: {
            summary: 'Empty Thumbnail Path',
            detail:
              'The thumbnail path cannot be empty. Please provide a valid path and try again.',
          },
          100306: {
            summary: 'Editor State Format Error',
            detail:
              'The editor state must be in dict/json format. Please correct it and try again.',
          },
          100307: {
            summary: 'Invalid Project ID',
            detail: 'The project ID provided is invalid. Please check the ID and try again.',
          },
          100308: {
            summary: 'Invalid Template ID',
            detail: 'The template ID provided is invalid. Please check the ID and try again.',
          },
          100309: {
            summary: 'Empty Workspace Name',
            detail:
              'The workspace name cannot be empty. Please provide a valid name and try again.',
          },
          100310: {
            summary: 'No .tex File',
            detail:
              'Your zip file must contain at least one .tex file. Please check and try again.',
          },
          100311: {
            summary: 'Multiple .tex Files Unsupported',
            detail: 'Multiple .tex files are not supported. Please include only one and try again.',
          },
          100312: {
            summary: 'Incorrect Zip Format',
            detail: 'The zip file format is incorrect. Please check and try again.',
          },
          100313: {
            summary: 'Invalid Zip File',
            detail: 'The zip file is invalid. Please check the file and try again.',
          },
          100314: {
            summary: 'Invalid Project Name',
            detail:
              'The project name must be between 1~128 characters and without special characters. Please adjust and try again.',
          },
          // 100316 is deprecated. because we accept all file extensions.
          100315: {
            summary: 'User Info Update Failed',
            detail: 'Failed to update user info. Please check the fields and try again.',
          },
          // 100317 is deprecated since we accept change to file extensions.
          100318: {
            summary: 'Unicode decode error',
            detail: 'Failed to decode the file. Please check the file encoding and try again.',
          },
          100400: {
            summary: 'Failed Precondition',
            detail: 'A precondition for this request was not met. Please check and try again.',
          },
          100401: {
            summary: 'Invalid Request',
            detail: 'Failed to process request (unprocessable entity). Please check and try again.',
          },
          100500: {
            summary: 'Out of Range',
            detail: 'A parameter is out of the allowable range. Please adjust it and try again.',
          },
          100600: {
            summary: 'Resource Not Found',
            detail:
              'The requested resource could not be found. Please check the request and try again.',
          },
          100601: {
            summary: 'Project Not Found',
            detail:
              'The specified project does not exist. Please check the project ID and try again.',
          },
          100602: {
            summary: 'User Not Found',
            detail: 'The specified user does not exist. Please check the user ID and try again.',
          },
          100603: {
            summary: 'Template Not Found',
            detail:
              'The specified template does not exist. Please check the template ID and try again.',
          },
          100604: {
            summary: 'File Not Found',
            detail:
              'The specified file could not be found. Please ensure it has been uploaded correctly.',
          },
          100605: {
            summary: 'Preview Result Not Found',
            detail:
              'Could not find the preview PDF result. Please try again or contact support for assistance.',
          },
          100606: {
            summary: 'User Not Found By Access Token',
            detail:
              'Could not find a user associated with the provided access token. Please authenticate and try again.',
          },
          100607: {
            summary: 'User Not Found By Refresh Token',
            detail:
              'Could not find a user associated with the provided refresh token. Please authenticate and try again.',
          },
          100700: {
            summary: 'Resource Lock',
            detail: 'The resource is currently locked. Please try again later.',
          },
          100800: {
            summary: 'Resource Already Exists',
            detail:
              'The resource you are trying to create already exists. Please check and try again.',
          },
          100801: {
            summary: 'Project Already Archived',
            detail: 'This project has already been archived and cannot be modified.',
          },
          100900: {
            summary: 'Quota Exceeded',
            detail:
              'You have exceeded your quota. Please wait before trying again or contact support to increase your quota.',
          },
          101000: {
            summary: 'Request Cancelled',
            detail: 'The request was cancelled. Please try again if this was unintended.',
          },
          101001: {
            summary: 'File Too Large',
            detail:
              'File size exceeds the maximum limit of 50 MB. Please reduce the file size and try again.',
          },
          101100: {
            summary: 'Data Loss',
            detail:
              'A serious error occurred resulting in data loss. Please contact support immediately.',
          },
          101200: {
            summary: 'Unknown Error',
            detail: 'An unknown error occurred. Please contact support for assistance.',
          },
          101300: {
            summary: 'Internal Server Error',
            detail:
              'An internal server error occurred. Please report this to the server administrator or developer.',
          },
          101301: {
            summary: 'LaTeXmk Not Installed',
            detail:
              'LaTeXmk is not installed, which is required for PDF preview. Please install LaTeXmk and try again.',
          },
          101302: {
            summary: 'PDF Preview Failed',
            detail:
              'An internal issue occurred while generating the PDF preview. Please contact server admin for assistance.',
          },
          101303: {
            summary: 'PDF Process Died',
            detail:
              'The PDF generation process terminated unexpectedly. Please contact server admin for assistance.',
          },
          101304: {
            summary: 'Cleanup Failed',
            detail:
              'Failed to clean up files after PDF review. Please try again or contact support for assistance.',
          },
          101305: {
            summary: 'Preview Download Failed',
            detail:
              'Failed to download project before compilation. Please try again or contact support for assistance.',
          },
          101306: {
            summary: 'ZIP File Upload Failed',
            detail:
              'Failed to upload some files from your zip file during project creation. Please try again or contact support for assistance.',
          },
          101307: {
            summary: 'Compilation timeout',
            detail:
              'PDF preview compilation took too long (>20s). Please reduce your project size or contact support for assistance.',
          },
          101308: {
            summary: 'SyncTex Conversion Timeout',
            detail:
              'SyncTex conversion took too long (>3s). Please recompile and try again or contact support for assistance.',
          },
          101309: {
            summary: 'SyncTex Conversion Failed',
            detail:
              'SyncTex conversion failed. Please recompile and try again or contact support for assistance.',
          },
          101310: {
            summary: 'SyncTex Conversion Failed',
            detail:
              "SyncTex couldn't find your compiled PDF, please recompile your project and try again.",
          },
          101400: {
            summary: 'Not Implemented',
            detail:
              'The requested method is not implemented. Please check the documentation for supported methods.',
          },
          101500: {
            summary: 'Service Unavailable',
            detail:
              'The service is currently unavailable. Please try again later or contact support for more information.',
          },
          101600: {
            summary: 'Deadline Exceeded',
            detail:
              'The request took too long to process and was terminated. Please try again later or contact support for assistance.',
          },
          200001: {
            summary: 'Service on a break!',
            detail:
              'Hold tight. Our services are taking a moment. Retry shortly or contact support if the issue lingers.',
          },
          200002: {
            summary: 'Client Closed Request',
            detail: 'Request is cancelled.',
          },
        },
        common: {
          summary: 'Error',
          detail: 'An error occurred. Please try again later.',
        },
        popupClosed: {
          summary: 'PopUp Closed',
          detail: 'You closed pop-up.',
        },
        unknown: {
          summary: 'Unknown Error',
          detail: 'An unknown error occurred. Please contact support for assistance.',
        },
        addImageErrorAtNotParagraphNode: {
          summary: 'Image Error',
          detail:
            'Images must be inserted into regular paragraphs. Please check the position of the text cursor(caret).',
        },
        exceedMaxUploadFileNumber: {
          summary: 'Exceed Max Upload File Number',
          detail: 'You can upload up to {maxUploadFileNumber} files at once.',
        },
        expired: {
          alert: 'You have been logged out.',
        },
        cancel: {
          summary: 'Request Cancelled',
          detail: 'The request was cancelled. Please try again if this was unintended.',
        },
        collaborationDisconnected: {
          summary: 'Server disconnected',
          detail: 'Some data may not be saved due to server connection issues',
        },
        exceedMaxLength: {
          summary: 'Exceed Max Length',
          detail: 'The maximum length is {maxLength} characters.',
        },
        fileMovedOrDeleted: {
          summary: 'File Moved or Deleted',
          detail: '{fileName} has been moved or deleted.',
        },
        exceedMaxFileSize: {
          summary: 'Exceed Max File Size',
          detail: 'The maximum file size is {maxFileSize}.',
        },
      },
      url: {
        termsOfService: 'https://www.murfy.ai/legal/en-terms-of-service',
        privacyPolicy: 'https://www.murfy.ai/legal/en-privacy-policy',
        promotion: 'https://www.murfy.ai/legal/en-marketing-agreement',
      },
    },
  },
  ko: {
    global: {
      url: {
        termsOfService: 'https://www.murfy.ai/legal/ko-terms-of-service',
        privacyPolicy: 'https://www.murfy.ai/legal/ko-privacy-policy',
        promotion: 'https://www.murfy.ai/legal/kr-marketing-agreement',
      },
    },
  },
}
