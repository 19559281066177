<script setup lang="ts">
import { BaseInputText, ConfirmModal, ProjectList } from '@/components'
import { ListEmptyState, ModalController, RenameModal, SelectOne } from '@/components/legacy'
import { useDashboardStore } from '@/stores'
import { usePermission } from '@/stores/permission'
import { type ProjectInfoDetail, ProjectInfoSummary, Role } from '@murfy-package/api-client'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const { t } = useI18n()

const renameModal = ref(new ModalController())

const dashboardStore = useDashboardStore()
const { isLoading, sortedProjectList } = storeToRefs(dashboardStore)
const { fetchProjectList } = dashboardStore
const { fetchRolePermissions } = usePermission()
onMounted(() => {
  fetchProjectList()
  fetchRolePermissions()
})

const searchText = ref('')
const filterMode = ref<'all' | 'shared' | 'archived'>('all')
const filters = [
  { value: 'all', id: 'all', label: 'all' },
  { value: 'shared', id: 'shared', label: 'shared' },
  { value: 'archive', id: 'archive', label: 'archive' },
]
const filteredProjectList = computed(() => {
  // filtered by filter mode
  const filteredByMode =
    filterMode.value === 'all'
      ? sortedProjectList.value.filter((project) => !project.archived)
      : filterMode.value === 'shared'
        ? sortedProjectList.value.filter(
            (project) => project.role !== Role.owner && !project.archived,
          )
        : sortedProjectList.value.filter((project) => project.archived)
  // filtered by search text
  const filteredBySearch = filteredByMode.filter((project) => {
    if (!searchText.value) return true
    return project.name.toLowerCase().includes(searchText.value.toLowerCase())
  })
  return filteredBySearch
})

const onSubmitRename = (project: ProjectInfoDetail, newName: string) => {
  renameModal.value.disable()
  dashboardStore.renameProject(project.id, newName).finally(() => {
    renameModal.value.close()
  })
}

const downloadProject = (project: ProjectInfoSummary) => {
  dashboardStore.downloadProject(project.id)
}

const router = useRouter()
const openProject = (project: ProjectInfoDetail) => {
  router.push(`/editor/${project.id}`)
}

const confirmModalVisible = ref(false)
const confirmModalProps = ref<{
  header: string
  content: string
  actionType: 'default' | 'destructive'
  severity: 'primary' | 'secondary' | 'tertiary'
  onConfirm: () => void
}>({
  header: '',
  content: '',
  actionType: 'default',
  severity: 'primary',
  onConfirm: () => {},
})

const openArchiveModal = (projects: ProjectInfoSummary[]) => {
  const singleOrMultiple = projects.length === 1 ? 'single' : 'multiple'
  confirmModalProps.value = {
    header: t(`archiveModal.${singleOrMultiple}.header`),
    content: t(`archiveModal.${singleOrMultiple}.content`, [
      '- ' + projects.map((p) => p.name).join('\n- '),
    ]),
    actionType: 'destructive',
    severity: 'tertiary',
    onConfirm: () => {
      projects.forEach((project) => dashboardStore.archiveProject(project.id))
      confirmModalVisible.value = false
    },
  }
  confirmModalVisible.value = true
}
const openLeaveModal = (projects: ProjectInfoSummary[]) => {
  const singleOrMultiple = projects.length === 1 ? 'single' : 'multiple'
  confirmModalProps.value = {
    header: t(`leaveModal.${singleOrMultiple}.header`),
    content: t(`leaveModal.${singleOrMultiple}.content`, [
      '- ' + projects.map((p) => p.name).join('\n- '),
    ]),
    actionType: 'destructive',
    severity: 'tertiary',
    onConfirm: () => {
      projects.forEach((project) => dashboardStore.leaveProject(project.id))
      confirmModalVisible.value = false
    },
  }
  confirmModalVisible.value = true
}
const openRestoreModal = (projects: ProjectInfoSummary[]) => {
  const singleOrMultiple = projects.length === 1 ? 'single' : 'multiple'
  confirmModalProps.value = {
    header: t(`restoreModal.${singleOrMultiple}.header`),
    content: t(`restoreModal.${singleOrMultiple}.content`, [
      '- ' + projects.map((p) => p.name).join('\n- '),
    ]),
    actionType: 'default',
    severity: 'tertiary',
    onConfirm: () => {
      projects.forEach((project) => dashboardStore.restoreProject(project.id))
      confirmModalVisible.value = false
    },
  }
  confirmModalVisible.value = true
}
</script>

<template>
  <div :class="$style.container" class="bg-color-bg-global-primary">
    <span class="h1 gray-8">{{ t('myProjects') }}</span>
    <div :class="$style.filterContainer">
      <SelectOne v-model:value="filterMode" name="filter" :items="filters" />
      <BaseInputText
        v-model="searchText"
        class="bg-gray-white-n-black w-[400px]"
        :placeholder="t('searchTextPlaceholder')"
      />
    </div>
    <ProjectList v-if="isLoading" :projects="[]" :loading="isLoading" />
    <ListEmptyState v-else-if="sortedProjectList.length === 0" type="empty" />
    <ListEmptyState v-else-if="filteredProjectList.length === 0" type="search" />
    <ProjectList
      v-else
      :projects="filteredProjectList"
      @open="openProject"
      @edit="(project) => renameModal.open(project)"
      @download="downloadProject"
      @archiveSelected="openArchiveModal"
      @restoreSelected="openRestoreModal"
      @leaveSelected="openLeaveModal"
    />
  </div>
  <RenameModal :controller="renameModal" @close="renameModal.close()" @submit="onSubmitRename" />
  <ConfirmModal
    v-bind="confirmModalProps"
    :visible="confirmModalVisible"
    @cancel="confirmModalVisible = false"
  />
</template>
<style module>
.container {
  height: 100%;
  overflow: auto;
  padding: 48px 48px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.filterContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
</style>

<i18n>
{
  "en": {
    "myProjects": "My Projects",
    "all": "All",
    "archive": "Archive",
    "searchTextPlaceholder": "Search in all projects...",    
    "leaveModal": {
      "single": {
        "header": "Leave Project",
        "content": "Your access will be revoked. If you have invite link, You can rejoin the project. Are you sure you want to leave this project?\n\n{0}",
      },
      "multiple": {
        "header": "Leave Projects",
        "content": "Your access will be revoked. If you have invite link, You can rejoin the project. Are you sure you want to leave these projects?\n\n{0}",
      },
    },
    "archiveModal": {
      "single": {
        "header": "Archive Project",
        "content": "Archived projects will be hidden from all members. Are you sure you want to archive this project?\n\n{0}",
      },
      "multiple": {
        "header": "Archive Projects",
        "content": "Archived projects will be hidden from all members. Are you sure you want to archive these projects?\n\n{0}",
      },
    },
    "restoreModal": {
      "single": {
        "header": "Restore Project",
        "content": "Are you sure you want to restore this project?\n\n{0}",
      },
      "multiple": {
        "header": "Restore Projects",
        "content": "Are you sure you want to restore these projects?\n\n{0}",
      },
    },
  }
}
</i18n>
