import { useSyncTexStore } from '@/stores'
import { Decoration, DecorationSet, EditorView, ViewPlugin } from '@codemirror/view'
import { watch } from 'vue'

class SyncPdfToCode {
  decorations: DecorationSet

  constructor(view: EditorView) {
    this.decorations = Decoration.none // 초기 데코레이션 설정
    this.watchSyncTexStore(view)
  }

  watchSyncTexStore(view: EditorView): void {
    const syncTexStore = useSyncTexStore()

    watch(
      () => syncTexStore.syncPdfToCodeResults,
      (newVal) => {
        if (newVal.length === 0) return
        const result = newVal[0]
        const { row } = result

        // CodeMirror의 라인 번호는 1부터 시작
        const line = view.state.doc.line(row)
        if (line) {
          // 열이 라인 길이를 초과하지 않도록 조정
          const safeColumn = Math.min(0, line.length)
          const pos = line.from + safeColumn

          view.dispatch({
            selection: { anchor: pos, head: line.to },
            scrollIntoView: true,
          })
        }
      },
      { immediate: false },
    )
  }
}

const syncPdfToCode = ViewPlugin.fromClass(SyncPdfToCode, {
  decorations: (v) => v.decorations,
})

export default syncPdfToCode
