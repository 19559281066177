/// <reference types="node" />
import { COLLABORATIVE_EDITS_WS_URL } from '@/config'
import { useEditor } from '@/editor'
import { i18n } from '@/main'
import { toastService } from '@/utils/toast'
import type { Extension } from '@codemirror/state'
import { EditorView } from '@codemirror/view'
import { APIError, ERROR_CODE, useAPIClient } from '@murfy-package/api-client'
import { defineStore, storeToRefs } from 'pinia'
import { computed, ref, shallowRef, watch } from 'vue'

import { type ConnectionState, collaborator } from '@/editor/extensions/collaborator'
import { getLanguageByExtension } from '@/editor/extensions/languages'

import { useCommentStore } from './comment'
import { useErrorStore } from './error'
import { useModalStore } from './modal'
import { useProjectStore } from './project'
import { useProjectFileStore } from './projectFile'
import { ProjectUIMode, useProjectUIStore } from './projectUI'
import { useUserStore } from './user'

export const useEditorStore = defineStore('editor', () => {
  const apiClient = useAPIClient()
  const projectStore = useProjectStore()
  const projectFileStore = useProjectFileStore()

  /**
   * 자동 저장을 위한 타이머 객체입니다.
   */
  const autoSaveTimer = ref<NodeJS.Timeout | null>(null)

  /**
   * 저장 중임을 가리키는 boolean 값 입니다.
   */
  const isSaving = ref(false)

  /**
   * 문서가 수정되었으며, 아직 저장되지 않았음을 가리키는 boolean 값 입니다.
   */
  const isModifiedAfterSave = ref(false)

  /**
   * 마지막으로 랜더링한 이 후, 문서가 수정되었는지 여부를 가리킵니다.
   */
  const isModifiedAfterRender = ref(false)

  /**
   * 현재 열려 있는 파일의 경로를 가리킵니다.
   * 이 상태가 변경될 때, 파일을 여는 로직이 동작합니다.
   * src/apps/editor/pages/EditorPage.vue 를 참고 부탁드립니다.
   */
  const currentFilePath = ref(null as string | null)

  /**
   * 현재 파일 포맷을 보고 언어를 판단합니다.
   */
  const language = computed(() => {
    const extension = currentFilePath.value?.split('.').pop()
    return getLanguageByExtension(extension)
  })
  /**
   * 현재 열려 있는 문서의 내용을 가리킵니다.
   */
  const currentFileContent = ref('')

  /** Web Socket 관련 */
  const websocketConnectionState = ref<ConnectionState>('connecting')
  const setConnectionState = (state: ConnectionState) => {
    // 이미 상태가 같다면 무시
    if (state === websocketConnectionState.value) {
      return
    }
    // synced 상태에서 connected로 변경되는 경우에만 무시
    // 실제로 존재하지 않는 상태라고 추정
    if (websocketConnectionState.value === 'synced' && state === 'connected') {
      return
    }
    // 현재 열려있는 파일이 삭제되거나 이름이 변경된 경우임.
    if (state === 'deleted') {
      // 본인이 변경하지 않은 경우만 toast를 띄움
      const shouldShowToast = !projectFileStore.isCurrentFileMovingOrDeleting
      projectStore.fetchProject().then(() => {
        if (shouldShowToast) {
          toastService.error(
            i18n.t('global.error.fileMovedOrDeleted.summary'),
            i18n.t('global.error.fileMovedOrDeleted.detail', { fileName: currentFilePath.value }),
          )
          openFile(null)
        }
      })
    }
    websocketConnectionState.value = state
  }
  // @ts-expect-error : Extension 타입이 재귀적으로 정의되어 있어서 타입 에러가 발생함.
  const collaborationExtension = ref<Extension | null>(null)
  const connectFileWebsocket = (targetFilePath: string) => {
    // 이미 연결이 있는 상태라면 연결을 끊음
    if (collaborationExtension.value) {
      collaborationExtension.value.close()
      collaborationExtension.value = null
    }
    // 파일이 선택되지 않았다면 연결을 하지 않음
    if (!targetFilePath) {
      return
    }
    const { me } = useUserStore()
    collaborator(
      COLLABORATIVE_EDITS_WS_URL,
      targetFilePath,
      me?.name || '',
      // 현재 파일로 협업 세션을 생성
      async (filePath) =>
        // @ts-expect-error : collaborator의 타입정의가 잘못되어 있어서 타입 에러가 발생함.
        await apiClient.collaborate.issueCollaborateRoomToken(
          //FIXME: projectId가 null이면 에러가 발생함, 현재는 projectId가 null이 되는 경우가 없음
          projectStore.projectId || '',
          filePath,
        ),
      setConnectionState,
    ).then((extension) => {
      collaborationExtension.value = extension
    })
  }
  /**
   * 파일을 여는 중임을 가리키는 boolean 값 입니다.
   */
  const isLoading = ref(true)
  /**
   * FIXME: 로딩관련 플래그 정리 필요
   * textFile인지 확인 + websocket 연결까지 포함한 플래그.
   * 파일을 여는 도중 파일을 클릭했을 때 중복으로 파일이 열리는 것을 방지하기 위함.
   */
  const isOpeningFile = ref(false)
  const openFile = async (filePath: string | null) => {
    if (isOpeningFile.value) {
      return
    }
    if (filePath === null) {
      currentFilePath.value = null
      collaborationExtension.value?.close()
      collaborationExtension.value = null
      return
    }
    if (!projectStore.projectId) {
      return
    }
    if (currentFilePath.value === filePath) {
      return
    }
    // FIXME: circular dependency 문제 해결 필요. comment가 socket을 통해 저장되면 필요없음.
    if (isModifiedAfterSave.value) {
      const { saveAllCommentLocations } = useCommentStore()
      saveAllCommentLocations()
    }
    isLoading.value = true
    isOpeningFile.value = true
    // text file로 열 수 있는 지 확인

    try {
      const projectTextFile = await apiClient.project.openTextFile(projectStore.projectId, filePath)
      // FIXME: 웹소켓 연결 전 isLoading을 false로 변경해야 Editor 컴포넌트가 마운트되어 내용이 보임. isLoading 변수 이름이 과연 적절할까...
      isLoading.value = false
      setConnectionState('syncing')
      connectFileWebsocket(filePath)
      await new Promise<void>((resolve, reject) => {
        const unwatch = watch(websocketConnectionState, (state) => {
          if (state === 'synced') {
            currentFileContent.value = projectTextFile.content
            currentFilePath.value = filePath
            const { mode } = storeToRefs(useProjectUIStore())
            mode.value = ProjectUIMode.Edit
            isLoading.value = false
            isOpeningFile.value = false
            unwatch()
            resolve()
          }
        })

        // 예외 처리 추가
        setTimeout(() => {
          unwatch()
          reject(new Error('Websocket sync timed out'))
          isLoading.value = false
          isOpeningFile.value = false
        }, 10000) // 타임아웃 예시, 필요에 따라 조정 가능
      })
    } catch (error) {
      if (error instanceof APIError) {
        if (error.errorCode === ERROR_CODE.UNICODE_DECODE_ERROR) {
          toastService.previewUnsupported(filePath)
          return
        } else if (error.statusCode === 404) {
          projectStore.fetchProject()
          setError(error)
          return
        }
      } else {
        setError(error)
      }
      isLoading.value = false
      isOpeningFile.value = false
    }
  }
  const { setError } = useErrorStore()

  const save = async () => {
    if (!projectStore.projectId || !currentFilePath.value) {
      return
    }
    //FIXME: yjs 사용하므로 문서 저장은 서버에서 알아서 한다. 댓글은 저장해야함.
  }

  // Control Editor
  const editorView = shallowRef<EditorView | null>(null)
  const setEditorView = (view: EditorView) => {
    editorView.value = view
  }
  const useCursor = () => {
    if (!editorView.value) return
    return useEditor().useCursor(editorView.value)
  }

  const $reset = () => {
    useProjectUIStore().$reset()
    useProjectStore().$reset()
    useProjectFileStore().$reset()
    useModalStore().$reset()
    autoSaveTimer.value = null
    isSaving.value = false
    isModifiedAfterSave.value = false
    isModifiedAfterRender.value = false
    currentFilePath.value = null
    currentFileContent.value = ''
    isLoading.value = false
  }
  return {
    autoSaveTimer,
    isSaving,
    isModifiedAfterSave,
    isModifiedAfterRender,
    isLoading,
    currentFilePath,
    language,
    currentFileContent,
    save,
    openFile,
    $reset,
    editorView,
    setEditorView,
    useCursor,
    apiClient,
    websocketConnectionState,
    setConnectionState,
    collaborationExtension,
  }
})
