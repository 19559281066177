import { defineStore } from 'pinia'
import { ref } from 'vue'

/**
 * FIXME: Modal을 좀 더 체계적으로 관리할 수 있도록 리팩토링이 필요합니다.
 */
export const useModalStore = defineStore('modal', () => {
  const fileUploadModalVisible = ref(false)

  const $reset = () => {
    fileUploadModalVisible.value = false
  }
  return {
    fileUploadModalVisible,
    $reset,
  }
})
