import axios from 'axios'
import { CanceledError } from 'axios'
import { type App, getCurrentInstance, inject } from 'vue'

import { AskAIAPI } from './api/askAI'
import { AuthAPI } from './api/auth'
import { CollaborateAPI } from './api/collaborate'
import { CommentAPI } from './api/comment'
import { InviteAPI } from './api/invite'
import { ProjectAPI } from './api/project'
import { SyncAPI } from './api/sync'
import { UserAPI } from './api/user'
import { WorkspaceAPI } from './api/workspace'
import { APIClient } from './client'
import { clearAccessToken, clearRefreshToken } from './utils'

/**
 * API class that contains all the API services.
 * This class is the main entry point for the API.
 *
 * @example
 * const api = new API('http://localhost:8000')
 * const user = await api.user.getMe('access-token')
 */
export class API {
  private readonly client: APIClient
  /**
   * User related API methods.
   * @example
   * const user = await api.user.getMe('access-token')
   * @example
   * const user = await api.user.updateMe('access-token', { givenName: 'Charlie', familyName: 'Brown' })
   * @example
   * const user = await api.user.activateMe('access-token')
   */
  user: UserAPI
  /**
   * Auth related API methods.
   * @example
   * const auth = await api.auth.googleLogin('code')
   */
  auth: AuthAPI
  /**
   * Workspace related API methods.
   * @example
   * const workspaces = await api.workspace.getList('access-token')
   */
  workspace: WorkspaceAPI
  /**
   * Project related API methods.
   * @example
   * const project = await api.project.get('access-token', 'project-id')
   * @example
   * const project = await api.project.create('access-token', { name: 'Project Name' })
   * ...
   */
  project: ProjectAPI
  askAI: AskAIAPI
  invite: InviteAPI
  comment: CommentAPI
  collaborate: CollaborateAPI
  sync: SyncAPI
  constructor(baseURL: string) {
    this.client = new APIClient(baseURL)
    this.user = new UserAPI(this.client)
    this.auth = new AuthAPI(this.client)
    this.workspace = new WorkspaceAPI(this.client)
    this.project = new ProjectAPI(this.client)
    this.askAI = new AskAIAPI(this.client)
    this.invite = new InviteAPI(this.client)
    this.comment = new CommentAPI(this.client)
    this.collaborate = new CollaborateAPI(this.client)
    this.sync = new SyncAPI(this.client)
  }

  /**
   * Logout the user by clearing the access and refresh tokens.
   */
  logout() {
    clearAccessToken()
    clearRefreshToken()
  }

  static createCancelToken() {
    return axios.CancelToken.source()
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  static isCancel(error: any): error is typeof CanceledError {
    return APIClient.isCancel(error)
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $api: API
  }
}

interface CreateAPIClientOptions {
  /**
   * The base URL of the API.
   */
  baseURL: string
}

/**
 * Create an API client instance and register it as a Vue plugin.
 *
 * This function creates an API client with the specified base URL and registers
 * it as a Vue plugin, making it available in the global properties and for injection.
 *
 * @param {Object} options - Configuration options.
 * @param {string} options.baseURL - The base URL for the API client.
 * @returns {Object} Vue plugin object.
 * @example
 * const apiClient = createAPIClient({ baseURL: 'http://localhost:8000' })
 * app.use(apiClient)
 */
export const createAPIClient = (options: CreateAPIClientOptions) => ({
  install: (app: App) => {
    const apiClient = new API(options.baseURL)
    app.provide('apiClient', apiClient)
    app.config.globalProperties.$api = apiClient
  },
})

/**
 * Use the API client instance.
 *
 * This function retrieves the API client instance from the Vue context. It ensures
 * that it is called within a Vue component's setup function, throwing an error if used
 * outside of a Vue component.
 *
 * @returns {API} The API client instance.
 * @throws {Error} If called outside of a Vue component's setup function or if the API client is not provided.
 * @example
 * const api = useAPIClient()
 * api.user.getMe('access-token').then((res) => { console.log(res) })
 */
export const useAPIClient = (): API => {
  const instance = getCurrentInstance()
  if (!instance) {
    throw new Error('useAPIClient must be called from within a setup function')
  }
  const api = inject<API>('apiClient')

  if (!api) {
    throw new Error('API client is not provided')
  }
  return api
}

export { AskAIAPI, AuthAPI, ProjectAPI, UserAPI, WorkspaceAPI, InviteAPI, CommentAPI, SyncAPI }
export { APIError, ERROR_CODE } from './schema/error'
export type { CancelToken } from 'axios'
export { Role, Permission, type Comment, type Reply } from './schema/response'

// export all types in schema/response.js
export type * from './schema/response'
export type * from './schema/request'
