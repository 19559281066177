<script setup lang="ts">
import { TextOverflowWithTooltip } from '@/components'
import { ModalController, UploadZipModal } from '@/components/legacy'
import { useDashboardStore, useErrorStore } from '@/stores'
import { API } from '@murfy-package/api-client'
import { BaseButton } from '@murfy-package/ui'
import { IconBase, IconListItem } from '@murfy-package/ui'
import { storeToRefs } from 'pinia'
import type { FileUploadUploadEvent } from 'primevue/fileupload'
import PanelMenu from 'primevue/panelmenu'
import { watch } from 'vue'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const { t } = useI18n()

const { recentActiveProjectList } = storeToRefs(useDashboardStore())
const { uploadProject, createProject } = useDashboardStore()
const { setError } = useErrorStore()
const recentProjectItems = computed(() =>
  recentActiveProjectList.value.map((project) => ({
    label: project.name,
    class: 'p2',
    url: '/editor/' + project.id,
  })),
)
const menuItems = computed(() => [
  {
    key: '0',
    label: t('recent'),
    class: 'h5',
    items: recentProjectItems.value,
  },
])
const expandedKeys = ref<Record<string, boolean>>({ '0': true })
const isExpandedKey = (key: string | undefined) => {
  if (!key) return false
  return expandedKeys.value[key]
}

const isCreatingNewProject = ref(false)
const onClickCrateNewProject = () => {
  isCreatingNewProject.value = true
  createProject(t('newName'))
    .then((createdProject) => {
      router.push(`/editor/` + createdProject.id)
    })
    .catch((error) => {
      setError(error)
    })
    .finally(() => {
      isCreatingNewProject.value = false
    })
}

const uploadZipModal = ref(new ModalController({ isLoading: false, isUnsupportedFile: false }))
const router = useRouter()
let canceler = () => {}
const onUpload = (event: FileUploadUploadEvent) => {
  const uploadFile = Array.isArray(event.files) ? event.files[0] : event.files
  uploadZipModal.value.isUploading = true
  // Cancel token은 매 요청마다 달라져야함.
  const { token, cancel } = API.createCancelToken()
  canceler = cancel
  uploadProject(uploadFile, token)
    .then((createdProject) => {
      router.push(`/editor/` + createdProject.id)
    })
    .catch((error) => {
      setError(error)
    })
    .finally(() => {
      uploadZipModal.value.isUploading = false
      uploadZipModal.value.close()
    })
}
watch(
  () => uploadZipModal.value.visible,
  (visible) => !visible && canceler(),
)
</script>

<template>
  <div :class="$style.sideBox">
    <PanelMenu
      v-model:expandedKeys="expandedKeys"
      :model="menuItems"
      class="w-full"
      :pt="{
        headerContent: $style.panelMenuHeaderContent,
        menuContent: $style.panelMenuContent,
      }"
    >
      <template #item="{ item }">
        <!-- [Default] 새 창으로 이동 -->
        <a
          v-if="item.url"
          :class="$style.listItem"
          class="hover:bg-cta-secondary-hover"
          :href="item.url"
          :target="item.target"
        >
          <span :class="$style.listItemLabelContainer">
            <span class="gray-5">
              <IconBase viewBox="0 0 6 6" width="6" height="6" iconName="listItem">
                <IconListItem />
              </IconBase>
            </span>
            <TextOverflowWithTooltip :class="item.class" :text="String(item.label)" />
          </span>
          <img src="/icons/chevron_right.svg" />
        </a>
        <!-- [Expandable] -->
        <div v-else :class="$style.listItem" :target="item.target">
          <span :class="item.class">{{ item.label }}</span>
          <img v-if="isExpandedKey(item.key)" src="/icons/expand_less.svg" />
          <img v-else src="/icons/expand_more.svg" />
        </div>
      </template>
    </PanelMenu>
    <div :class="$style.buttonBox">
      <BaseButton scale="small" :loading="isCreatingNewProject" @click="onClickCrateNewProject">
        {{ t('createNew') }}
      </BaseButton>
      <BaseButton scale="small" @click="uploadZipModal.open()">
        {{ t('upload') }}
      </BaseButton>
    </div>
  </div>
  <UploadZipModal :controller="uploadZipModal" @close="uploadZipModal.close()" @upload="onUpload" />
</template>

<style module>
.sideBox {
  padding: 16px 0;
  background-color: var(--color-bg-global-secondary);
  height: 100%;
  display: flex;
  flex-direction: column;
}
.listItem {
  text-decoration: none;
  background-color: transparent;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  cursor: pointer;
}
.sidebarMenuButton {
  padding: 10px 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonBox {
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.panelMenuHeaderContent {
  background-color: transparent;
  border: none;
  padding: 0;
}
.panelMenuContent {
  background-color: transparent;
  padding: 0;
  border: none;
}
.listItemLabelContainer {
  /* 24px is for the icon */
  width: calc(100% - 24px);
  display: flex;
  gap: 8px;
}
</style>

<i18n>
{
  "en":{
    "recent": "Recent Projects",
    "all": "All Projects",
    "myProject": "My Workspace",
    "createNew": "Blank Project",
    "upload": "Upload Project",
    "newName": "New Document",
  }
}
</i18n>
