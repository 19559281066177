import type { PDFViewerPageWrapper, PDFViewerViewPortRect } from '@/components/PDFViewer'
import { PDF_VIEWER_PAGE_INDEX_NUMBER_START_VALUE } from '@/config'
import { useProjectCompileStore } from '@/stores'
import { getDocument } from 'pdfjs-dist'
import { storeToRefs } from 'pinia'
import { onBeforeUnmount } from 'vue'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export interface LoadStateOption {
  onAllPageRendered?: () => void
}

const SHA256Hash = async (input: string) => {
  const textAsBuffer = new TextEncoder().encode(input)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  return hashArray.map((item) => item.toString(16).padStart(2, '0')).join('')
}

export const usePDFViewerLoadState = (options: LoadStateOption = {}) => {
  const { t } = useI18n()
  const pages = ref<PDFViewerPageWrapper[]>([])
  const load = async (path: string) => {
    if (!path) {
      return
    }
    const sha256Hash = SHA256Hash(path)
    // pdfDocumentProxy는 reactive하게 표현되면 안됨
    // https://stackoverflow.com/questions/54906710/vuejs-disable-reactivity-for-specific-properties
    // 구현 문제로 에러가 남
    const pdfDocumentProxy = await getDocument(path).promise
    const maxPages = pdfDocumentProxy.numPages
    if (maxPages === 0) {
      throw new Error(t('noPages'))
    }
    // page index number는 1부터 시작
    for (let i = PDF_VIEWER_PAGE_INDEX_NUMBER_START_VALUE; i <= maxPages; i++) {
      const key = `${sha256Hash}-${i}`
      pages.value.push({
        page: pdfDocumentProxy.getPage(i),
        key,
        pageIndexNumber: i,
      })
    }
  }

  const renderViewports = ref({} as Record<string, PDFViewerViewPortRect>)
  const isAllRendered = computed(() => pages.value.length === renderedPageCount.value)
  const renderedPageCount = computed(() => Object.values(renderViewports.value).length)

  const showLoadingProgress = computed(() => !isAllRendered.value && maxLoadingProgress.value > 0)

  const { isRenderingPdf } = storeToRefs(useProjectCompileStore())
  const showLoadingState = computed<boolean>(() => {
    if (isRenderingPdf.value) {
      return true
    }
    if (pages.value.length < 1) {
      return true
    }
    return !isAllRendered.value
  })
  const firstRendered = ref(false)
  const { onAllPageRendered } = options
  watch(isAllRendered, (isAll) => {
    if (!isAll) {
      return
    }
    if (!firstRendered.value) {
      firstRendered.value = true
    }
    if (onAllPageRendered) {
      onAllPageRendered()
    }
  })

  const maxWidth = ref(0)
  const maxHeight = ref(0)
  const documentHeight = ref(0)
  // 최대 너비, 높이를 계산 - 줌을 위해서
  const setRenderViewport = (key: string, viewport: PDFViewerViewPortRect) => {
    if (!firstRendered.value) {
      documentHeight.value += viewport.height
    }
    renderViewports.value[key] = viewport
    maxWidth.value = Math.max(viewport.width, maxWidth.value)
    maxHeight.value = Math.max(viewport.height, maxHeight.value)
  }

  const loadingProgress = computed(() => (isAllRendered.value ? 0 : renderedPageCount.value))
  const maxLoadingProgress = computed(() => (isAllRendered.value ? 0 : pages.value.length))

  const reset = () => {
    renderViewports.value = {}
    maxWidth.value = 0
    maxHeight.value = 0
    documentHeight.value = 0
    pages.value = []
  }

  onBeforeUnmount(() => {
    reset()
  })

  const reload = async (v: string) => {
    // 재로드시 리셋
    reset()
    return load(v)
  }
  return {
    renderViewports,
    showLoadingState,
    setRenderViewport,
    showLoadingProgress,
    loadingProgress,
    maxLoadingProgress,
    maxHeight,
    maxWidth,
    documentHeight,
    reset,
    load,
    pages,
    reload,
  }
}
