<script setup lang="ts">
import { useErrorStore, useProjectStore } from '@/stores'
import { usePermission } from '@/stores/permission'
import { Permission, useAPIClient } from '@murfy-package/api-client'
import { BaseButton, TextInput } from '@murfy-package/murds'
import { storeToRefs } from 'pinia'
import { watch } from 'vue'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { AccessControl } from '..'

const { t } = useI18n()
const apiClient = useAPIClient()
const { setError } = useErrorStore()
const { project } = storeToRefs(useProjectStore())

const focused = ref(false)
const timer = ref<NodeJS.Timeout | null>(null)
const inputBoxRef = ref<HTMLInputElement | null>(null)
const viewProjectName = ref('')

watch(
  project,
  (newProjectInfo) => (viewProjectName.value = newProjectInfo?.name ?? viewProjectName.value),
)
/**
 * Set focus on the input box
 */
const setFocus = () => {
  inputBoxRef.value?.focus()
}

defineExpose({
  setFocus,
})

const onFocus = () => {
  focused.value = true
  if (timer.value) {
    clearTimeout(timer.value)
    timer.value = null
  }
}

const onBlur = () => {
  // 버튼 클릭 이벤트 보다 블러 이벤트가 먼저 발생하기 때문에 블러를 조금 지연시킴.
  // 블러 이벤트가 발생하면 버튼이 사라져 버튼 클릭 이벤트가 발생 안됨.
  timer.value = setTimeout(() => {
    focused.value = false
    viewProjectName.value = project.value?.name ?? ''
  }, 100)
}

const onSaveTitleWithEnter = () => {
  if (inputBoxRef.value) {
    inputBoxRef.value.blur()
  }
  onSaveTitle()
}

const onSaveTitle = () => {
  if (timer.value !== null) {
    // This is to cancel the onBlur event
    clearTimeout(timer.value)
    timer.value = null
  }
  if (!inputBoxRef.value || !project.value) {
    focused.value = false
    return
  }
  const newName = viewProjectName.value
  if (project.value.name === newName) {
    focused.value = false
    return
  }

  apiClient.project
    .rename(project.value.id, newName)
    .then((newProject) => {
      project.value = { ...project.value, ...newProject }
    })
    .catch((error) => {
      setError(error)
      if (!project.value) {
        return
      }
      viewProjectName.value = project.value.name
    })

  focused.value = false
}

const { checkPermission } = usePermission()
</script>

<template>
  <div class="flex gap-4">
    <TextInput
      id="renameInput"
      ref="inputBoxRef"
      v-model="viewProjectName"
      :placeholder="t('titlePlaceholder')"
      type="text"
      class="w-[360px]"
      :readonly="!checkPermission(Permission.projectUpdate)"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.enter="onSaveTitleWithEnter"
    />
    <AccessControl :permissions="[Permission.projectUpdate]">
      <span v-if="focused" class="z-10 block">
        <BaseButton severity="info" @click.prevent="onSaveTitle">
          {{ t('titleSave') }}
        </BaseButton>
      </span>
    </AccessControl>
  </div>
</template>

<i18n>
{
  "en": {
    "titlePlaceholder": "Untitled",
    "titleSave": "Save",
  }
}
</i18n>
