import { useErrorStore } from '@/stores'
import {
  SyncCodeToPdfResponse,
  SyncPdfToCodeResponse,
  useAPIClient,
} from '@murfy-package/api-client'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useSyncTexStore = defineStore('syncTex', () => {
  const apiClient = useAPIClient()
  const errorStore = useErrorStore()

  // 상태 변수
  const loadingSyncCodeToPdf = ref(false)
  const loadingSyncPdfToCode = ref(false)
  const syncCodeToPdfResults = ref<SyncCodeToPdfResponse[]>([])
  const syncPdfToCodeResults = ref<SyncPdfToCodeResponse[]>([])

  /**
   * LaTeX 행과 열 정보를 기반으로 PDF 위치 정보를 가져오기
   * @param projectId 프로젝트 ID
   * @param texPath TeX 파일 경로
   * @param row 행 번호
   * @param col 열 번호
   */
  const syncCodeToPdf = async (projectId: string, texPath: string, row: number, col: number) => {
    loadingSyncCodeToPdf.value = true
    try {
      const results = await apiClient.sync.syncCodeToPdf(projectId, texPath, row, col)

      // 중복된 x, y 좌표 제거
      syncCodeToPdfResults.value = results.filter(
        (result, index, self) =>
          index === self.findIndex((t) => t.h === result.h && t.v === result.v),
      )
    } catch (error) {
      errorStore.setError(error)
    } finally {
      loadingSyncCodeToPdf.value = false
    }
  }

  /**
   * PDF 위치 정보를 기반으로 LaTeX 코드 위치를 가져오기
   * @param projectId 프로젝트 ID
   * @param page 페이지 번호
   * @param h 수평 위치 (정수 O, 소수 X)
   * @param v 수직 위치 (정수 O, 소수 X)
   */
  const syncPdfToCode = async (projectId: string, page: number, h: number, v: number) => {
    loadingSyncPdfToCode.value = true
    try {
      const hInteger = Math.floor(h)
      const vInteger = Math.floor(v)

      syncPdfToCodeResults.value = await apiClient.sync.syncPdfToCode(
        projectId,
        page,
        hInteger,
        vInteger,
      )
    } catch (error) {
      errorStore.setError(error)
    } finally {
      loadingSyncPdfToCode.value = false
    }
  }

  return {
    // 상태
    loadingSyncCodeToPdf,
    loadingSyncPdfToCode,
    syncCodeToPdfResults,
    syncPdfToCodeResults,

    // 메소드
    syncCodeToPdf,
    syncPdfToCode,
  }
})
