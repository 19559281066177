<script setup lang="ts">
import {
  AccessControl,
  EditorHeader,
  EditorSide,
  FilePreviewUnsupportedToast,
  FileUploadModal,
  ProjectCompileResult,
} from '@/components'
import AskAI from '@/components/AskAI.vue'
import CommentFloating from '@/components/CommentFloating.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'
import ToggleButton from '@/components/ToggleButton.vue'
import { ASK_AI_BUTTON_DELAY_MS } from '@/config'
import { type EditorPayload, MurfyEditor, useEditor } from '@/editor'
import {
  ProjectUIMode,
  useCommentStore,
  useEditorStore,
  useErrorStore,
  useProjectCompileStore,
  useProjectStore,
  useProjectUIStore,
} from '@/stores'
import { useBibFileStore } from '@/stores/bibFile'
import { usePermission } from '@/stores/permission'
import { toastService } from '@/utils/toast'
import { useEditorStyle } from '@/views/EditorViewEditorStyle'
import NoFileSelected from '@/views/EditorViewNoFileSelected.vue'
import { useContainerBoundedCoordsStyle } from '@/views/useContainerBoundedCoords'
import { EditorState, SelectionRange, StateEffect, Text } from '@codemirror/state'
import { EditorView, type ViewUpdate, keymap } from '@codemirror/view'
import { Permission } from '@murfy-package/api-client'
import type { LogEntry } from '@murfy-package/latex-log-parser'
import {
  ActivityBar,
  IconBase,
  IconCircleCheck,
  IconCircleInfo,
  IconCommentLine,
  IconFileEmpty,
  IconFunction,
  IconShareNodes,
} from '@murfy-package/murds'
import { BaseProgressSpinner } from '@murfy-package/ui'
import { storeToRefs } from 'pinia'
import {
  type ComputedRef,
  type Ref,
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  shallowRef,
  toRaw,
  watch,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { getExtensionsByLanguage } from '@/editor/config'
import { bibKeywords, bibliographyFiles } from '@/editor/extensions/autocomplete/latexKeywords'
import { addCommentClickHandler } from '@/editor/extensions/commentMark'
import type { Range } from '@/editor/extensions/formula-turbo/formulaTurbo'
import type { Annotation } from '@/editor/extensions/languages/latex/linter/helper/annotation'
import { setAnnotations } from '@/editor/extensions/languages/latex/linter/helper/annotations'
import { syncCodeToPdf } from '@/editor/extensions/syncCodeToPdf'
import { useMathPatterns } from '@/editor/hooks/useMathPatterns'

import { LoadingView } from '.'
import { Activity, ExtraActivity, useActivity } from './EditorViewActivity'

const editorStore = useEditorStore()
const {
  currentFilePath,
  language,
  isModifiedAfterSave,
  isModifiedAfterRender,
  isSaving,
  isLoading,
  autoSaveTimer,
  websocketConnectionState,
  collaborationExtension,
} = storeToRefs(editorStore)
const { openFile, save, setEditorView } = editorStore

const { openProject } = useProjectStore()
const { projectId } = storeToRefs(useProjectStore())

const { parsedCompileLog } = storeToRefs(useProjectCompileStore())
const { renderPdf } = useProjectCompileStore()

const commentStore = useCommentStore()
const {
  toggleComment,
  closeComment,
  isCommentPos,
  saveAllCommentLocations,
  updateCommentFloatingAnchor,
} = commentStore
const { selectedComment, commentFloatingAnchor, commentFloatingVisible } = storeToRefs(commentStore)

const { layout, mode } = storeToRefs(useProjectUIStore())

const { setError } = useErrorStore()

const route = useRoute()
const router = useRouter()
// FIXME: 로딩 부분은 route 훅에서 store를 호출해서 처리하는게 좋음
// - 레이아웃 코드가 간결해 짐
// - isLoading 도 스토어에 옮길 수 있음

const isPageLoading = ref(false)
const isFetchingProject = ref(false)
const isAccessDenied = ref(false)
const { parseBibFiles, parseBibFilesById, parseBibFilesFromDetail } = useBibFileStore()
const getReferences = () => {
  if (projectId.value) {
    parseBibFilesById(projectId.value).then((bib) => {
      bibliographyFiles.length = 0
      bibliographyFiles.push(...bib.bibKeywords)

      bibKeywords.length = 0
      bibKeywords.push(...bib.bibFiles)
    })
  }
}
watch(
  () => route.params.id,
  (id) => {
    editorStore.$reset()
    commentStore.$reset()
    isPageLoading.value = true
    isFetchingProject.value = true
    isAccessDenied.value = false
    const newProjectId = Array.isArray(id) ? id[0] : id
    openProject(newProjectId)
      .then((newProject) => {
        if (newProject.archived) {
          router.push('/dashboard')
          toastService.error(t('archivedProject.summary'), t('archivedProject.detail'), 0)
          return
        }

        // Bib 파일 내용 가져오기
        parseBibFilesFromDetail(newProject).then((bibData) => {
          bibKeywords.length = 0
          bibKeywords.push(...bibData)
        })

        // Bib 파일 목록 가져오기
        if (newProject.assets) {
          const bibFiles = parseBibFiles(newProject.assets)
          bibliographyFiles.length = 0
          bibliographyFiles.push(...bibFiles)
        }

        const lastOpenedTexPath = newProject?.lastOpenedTexPath
        if (lastOpenedTexPath) {
          openFile(lastOpenedTexPath)
            .catch((error) => {
              setError(error)
            })
            .finally(() => {
              isPageLoading.value = false
            })
        } else {
          isPageLoading.value = false
        }
      })
      .catch((error) => {
        if (error.statusCode === 403) {
          isAccessDenied.value = true
        } else {
          setError(error)
          router.push('/dashboard')
        }
      })
      .finally(() => {
        isFetchingProject.value = false
      })
  },
  {
    immediate: true,
  },
)

const { showResult, loaderStyle, editorWidthStyle, previewGutterRef, sidebarGutterRef } =
  useEditorStyle(mode, layout, isLoading)

const { useCursor, useSyntaxChecker } = useEditor()

/**
 * 에디터의 view 객체
 * 에디터가 준비되면 handleReady 함수에서 할당됨
 * FIXME:
 * shallowRef라 에디터의 상태가 변경되어도 변경 감지가 되지 않음
 * ref로 하면 변경 감지가 되지만, useCursor에서 view 객체를 사용할 때 에러가 발생함
 */
const view = shallowRef<EditorView>()

// 에디터가 준비되면 오는 delegate 함수
const cursorHeight = ref(0)
const handleReady = (payload: EditorPayload) => {
  setEditorView(payload.view)
  view.value = payload.view
  view.value.dispatch({
    effects: StateEffect.appendConfig.of(
      keymap.of([
        {
          key: 'Mod-s',
          run: () => {
            if (autoSaveTimer.value) {
              clearTimeout(autoSaveTimer.value)
            }
            autoSaveCallback()?.then(renderPdf)
            return true
          },
        },
        {
          key: 'Space',
          run: () => {
            if (!view.value || isCursorInMathEnvironment()) return false

            const cursorPos: number = view.value.state.selection.main.head
            const line = view.value.state.doc.lineAt(cursorPos)
            const text = view.value.state.doc.sliceString(line.from, line.to)

            // 라인이 비어 있는 경우에만 Ask AI 실행
            if (text === '') {
              updateShouldShowAI()
              return true
            }
            return false
          },
        },
      ]),
    ),
  })
  cursorHeight.value = useCursor(view.value).heightInPixel
}
// Syntax Checking
const { syntaxChecking, checkState } = useSyntaxChecker(view)

// 에디터 내부에서 상태 변경 되면 오는 함수
const change = (_event: string, _payload: string) => {
  if (websocketConnectionState.value !== 'synced') {
    return
  }
  isModifiedAfterSave.value = true
  isModifiedAfterRender.value = true
  if (autoSaveTimer.value) {
    clearTimeout(autoSaveTimer.value)
  }
  autoSaveTimer.value = setTimeout(autoSaveCallback, 1000)
}

/**
 * 커서 위치와 매칭 범위를 확인하는 공통 함수
 */
const isCursorInMathEnvironment = () => {
  if (!view.value) {
    return false
  }
  const cursorPos = useCursor(view.value).cursorPos
  const { ranges } = useMathPatterns(view.value.state.doc)
  const matchingRanges = ranges.value.filter(
    (range: Range) => cursorPos > range.from && cursorPos < range.to - range.offset,
  )
  return matchingRanges.length > 0
}

/**
 * Ask AI 버튼을 보여야 하는지 여부
 */
const shouldShowAI = ref(false)
let shouldShowAITimer: NodeJS.Timeout | null = null
/**
 * Ask AI 버튼을 보여야 하는지 계산. view가 업데이트 될 때마다 호출
 * - view가 없으면 보여주지 않음
 * - language가 latex가 아니면 보여주지 않음
 * - 커서가 비어있고, 커서가 있는 라인이 비어있을 경우, Tab을 입력하면 보여줌
 * - 커서가 비어있지 않으면 ( 선택된 텍스트가 있으면 ) 보여줌
 */
const updateShouldShowAI = () => {
  if (!view.value || isCursorInMathEnvironment()) {
    shouldShowAI.value = false
    return
  }

  const emptyCursor = useCursor(view.value).isEmpty
  const cursorLine = useCursor(view.value).line
  const emptyCursorInEmptyLine = emptyCursor && cursorLine.text === ''
  const newShouldShowAI = language.value === 'latex' && (!emptyCursor || emptyCursorInEmptyLine)
  if (newShouldShowAI && newShouldShowAI !== shouldShowAI.value) {
    // AI 버튼이 보여져야 하는 경우, ASK_AI_BUTTON_DELAY_MS 후에 보여줌
    // 텍스트 드래그 시 매 드래그마다 랜더링 되는 것을 방지하기 위함.
    if (shouldShowAITimer) {
      clearTimeout(shouldShowAITimer)
    }
    shouldShowAITimer = setTimeout(() => {
      shouldShowAI.value = true
    }, ASK_AI_BUTTON_DELAY_MS)
    shouldShowAI.value = false
    return
  }
  if (!newShouldShowAI && shouldShowAITimer) {
    clearTimeout(shouldShowAITimer)
  }
  shouldShowAI.value = newShouldShowAI
  return
}

// Ask AI 버튼 위치 계산
const editorArea = ref<HTMLElement | null>(null)
const askAIRef = ref<InstanceType<typeof AskAI> | null>(null)
const askAIElement = computed(() => askAIRef.value?.$el)
const cursorAnchor = ref({ top: 0, left: 0 })
const updateCursorAnchor = () => {
  if (!view.value) {
    return
  }
  const { rect } = useCursor(view.value)
  if (!rect) {
    return
  }
  cursorAnchor.value.top = rect.top
  cursorAnchor.value.left = rect.left
}
const askAIPosition = computed(() => (askAIRef.value?.isCurrentStateIdle ? 'top' : 'bottom'))
const askAIOffsetTop = computed(() => (askAIRef.value?.isCurrentStateIdle ? 0 : cursorHeight.value))
const { coordsStyle: askAIPositionStyle } = useContainerBoundedCoordsStyle({
  anchorCoords: cursorAnchor,
  container: editorArea,
  element: askAIElement,
  position: askAIPosition,
  offsetTop: askAIOffsetTop,
})

// Comment 관련 상태, 함수
//// Comment Floating 위치 계산
const commentFloatingRef = ref<InstanceType<typeof CommentFloating> | null>(null)
const commentFloatingElement = computed(
  () => commentFloatingRef.value?.$el,
) as ComputedRef<HTMLElement | null>
const { coordsStyle: commentFloatingPositionStyle } = useContainerBoundedCoordsStyle({
  anchorCoords: commentFloatingAnchor,
  container: editorArea,
  element: commentFloatingElement,
  boundY: false,
})

/**
 * view.state.selection.main을 반응형 상태로 변경하기 위해 별도로 선언.
 * view 가 ref로 변경될 수 있다면 필요 없음.
 */
const selection: Ref<SelectionRange | null> = ref(null)

const docRef: Ref<Text | null> = ref(null)

const update = (viewUpdate: ViewUpdate) => {
  // FIXME: 변경점이 없을 때도 지속적으로 호출되어 추가함.
  const changed =
    viewUpdate.docChanged ||
    viewUpdate.focusChanged ||
    viewUpdate.geometryChanged ||
    viewUpdate.heightChanged ||
    viewUpdate.selectionSet ||
    viewUpdate.viewportChanged
  if (!changed) return
  selection.value = viewUpdate.state.selection.main
  view.value = viewUpdate.view
  docRef.value = viewUpdate.state.doc
  updateCursorAnchor()
  updateCommentFloatingAnchor()
  if (viewUpdate.selectionSet) {
    shouldShowAI.value = false

    // 선택된 텍스트가 있는 경우 Ask Ai 가 보이도록 합니다.
    const selectedText = viewUpdate.state.doc
      .sliceString(selection.value.from, selection.value.to)
      .trim()
    if (selectedText.length > 0) {
      updateShouldShowAI()
    }
    if (!selection.value.empty || !isCommentPos(selection.value.from)) {
      closeComment()
    }
  }

  //FIXME: 변경내용이 제대로 전파되면 지울 것
  checkState(viewUpdate.state.doc)
}

const autoSaveCallback = () => {
  if (websocketConnectionState.value !== 'synced') {
    isModifiedAfterSave.value = false
    isSaving.value = false
    return
  }

  isSaving.value = true
  // bib 파일을 저장 하는 경우 새로운 Reference 가져오기
  language.value === 'bibtex' && getReferences()
  saveAllCommentLocations()
  return save()
    .then(() => {
      isModifiedAfterSave.value = false
    })
    .catch((e) => {
      setError(e)
    })
    .finally(() => {
      isSaving.value = false
    })
}

// 에디터에 focus 되면 오는 함수
const focus = (_event: string, _payload: ViewUpdate) => {}

// 에디터에 blur 되면 오는 함수
const blur = (_event: string, _payload: ViewUpdate) => {}
const { fetchRolePermissions } = usePermission()
onMounted(() => {
  window.addEventListener('beforeunload', handleBeforeUnload)
  fetchRolePermissions()
})
onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload)
  handleBeforeUnload()
})

const handleBeforeUnload = () => {
  if (isModifiedAfterSave.value) {
    if (autoSaveTimer.value) {
      clearTimeout(autoSaveTimer.value)
    }
    autoSaveCallback()
  }
  if (collaborationExtension.value) {
    collaborationExtension.value.close()
    collaborationExtension.value = null
  }
}

const { t } = useI18n()

watch([currentFilePath, parsedCompileLog, docRef], ([newFilePath, parsedLog, doc]) => {
  if (!view.value || !newFilePath || doc === view.value.state.doc) {
    return
  }
  // 문서 변경에 따라 annotation의 위치가 변경 될 수 있도록 하기 위해 랜더링 직후에만 annotation을 업데이트함.
  if (isModifiedAfterRender.value) {
    return
  }
  const annotations: Annotation[] = parsedLog
    .filter((logEntry: LogEntry) => logEntry.fileName === newFilePath)
    .map((logEntry: LogEntry) => ({
      row: logEntry.lineNumber ? logEntry.lineNumber - 1 : 0,
      type:
        logEntry.level === 'error' ? 'error' : logEntry.level === 'warning' ? 'warning' : 'info',
      text: logEntry.message,
    }))

  setTimeout(() => {
    view.value && doc && view.value.dispatch(setAnnotations(doc, annotations || []))
  }, 100)
})

const { checkPermission } = usePermission()
const isEditorReady = computed(
  () => !isFetchingProject.value && !isLoading.value && collaborationExtension.value,
)

const isNotSelected = computed(
  () => !(currentFilePath.value || isLoading.value || isFetchingProject.value),
)
const editorExtensions = computed(() => {
  if (!isEditorReady.value || !projectId.value) {
    return []
  }
  const languageExtensions = [getExtensionsByLanguage[language.value ?? 'default']]

  const editableExtension = EditorView.editable.of(checkPermission(Permission.fileUpdate))
  const readonlyExtension = EditorState.readOnly.of(!checkPermission(Permission.fileUpdate))
  const commentExtension = addCommentClickHandler(toggleComment)
  return [
    syncCodeToPdf(projectId.value, currentFilePath.value),
    toRaw(collaborationExtension.value?.extension),
    commentExtension,
    editableExtension,
    readonlyExtension,
    ...languageExtensions,
  ]
})

const { activities, activeActivity, extraActivities } = useActivity()

const showLoading = computed(
  () =>
    (!isEditorReady.value && !isNotSelected.value) ||
    (currentFilePath.value && websocketConnectionState.value !== 'synced'),
)

const isDisconnected = computed(() => websocketConnectionState.value === 'disconnected')
</script>

<template>
  <LoadingView v-if="isPageLoading" :message="t('loadingProject')" />
  <main
    v-if="!isAccessDenied"
    class="grid-rows-editor bg-gray-0 m-0 grid h-full w-full grid-cols-12 overflow-hidden overflow-x-auto p-0"
    :style="editorWidthStyle"
  >
    <EditorHeader class="col-end-last z-40 col-start-1 row-start-1 row-end-1 h-16" />
    <div
      v-if="showLoading"
      class="col-end-last row-end-last bg-gray-white-n-black z-30 col-start-[--loader-start-col] row-start-[--loader-start-row] flex flex-col items-center justify-center"
      :style="loaderStyle"
    >
      <BaseProgressSpinner />
      <p>{{ t('loading') }}</p>
    </div>
    <div
      class="row-end-last border-color-border-primary bg-color-bg-global-primary z-50 col-start-1 col-end-2 row-start-2 border-r"
    >
      <ActivityBar
        v-model="activeActivity"
        :activities="activities"
        :extraActivities="extraActivities"
        orientation="vertical"
      >
        <template #activity="{ activity }">
          <IconBase :width="24" :height="24">
            <IconFileEmpty v-if="activity === Activity.File" />
            <IconShareNodes v-else-if="activity === Activity.Share" />
            <IconCommentLine v-else-if="activity === Activity.Comment" />
            <IconFunction v-else-if="activity === Activity.FormulaTurbo" />
            <IconCircleInfo v-else-if="activity === ExtraActivity.Help" />
          </IconBase>
        </template>
      </ActivityBar>
    </div>
    <EditorSide
      :activeActivity="activeActivity"
      class="row-end-last z-50 col-start-2 col-end-3 row-start-2 overflow-visible"
    />
    <div
      ref="sidebarGutterRef"
      class="hover:bg-cta-pressed active:bg-cta-pressed row-end-last z-10 col-start-3 col-end-4 row-start-2 cursor-ew-resize bg-gray-300 transition-all duration-300"
      @mousedown.prevent
    />
    <div
      class="bg-gray-white-n-black z-10 col-start-[--editor-editor-start-col] col-end-[--editor-editor-end-col] row-span-1 row-start-2 flex flex-row items-center justify-between border-b border-solid border-gray-300 px-6 py-3"
    >
      <div class="head-xs text-color-text-primary flex items-center">{{ currentFilePath }}</div>
      <AccessControl :permissions="[Permission.fileUpdate]">
        <div class="flex items-center">
          <ToggleButton
            v-if="currentFilePath && language === 'latex'"
            v-model="syntaxChecking"
            textOnly
          >
            <span class="body-sm flex items-center gap-2">
              <IconBase iconName="check">
                <IconCircleCheck />
              </IconBase>
              {{ t('editor.inspections') }}
            </span>
          </ToggleButton>
        </div>
      </AccessControl>
    </div>

    <div
      ref="editorArea"
      class="row-end-last bg-color-bg-global-primary relative z-0 col-start-[--editor-editor-start-col] col-end-[--editor-editor-end-col] row-start-3 overflow-y-auto"
    >
      <template v-if="isEditorReady">
        <MurfyEditor
          class="w-full"
          :extensions="editorExtensions"
          @ready="handleReady"
          @change="change('change', $event)"
          @focus="focus('focus', $event)"
          @blur="blur('blur', $event)"
          @update="update($event)"
          @scroll="
            () => {
              updateCursorAnchor()
              updateCommentFloatingAnchor()
            }
          "
        />
        <AccessControl :permissions="[Permission.commentRead]">
          <CommentFloating
            ref="commentFloatingRef"
            :style="commentFloatingPositionStyle"
            :visible="commentFloatingVisible"
            :commentId="selectedComment?.id"
            @close="closeComment()"
          />
        </AccessControl>
        <AccessControl :permissions="[Permission.fileUpdate]">
          <AskAI
            v-if="view"
            ref="askAIRef"
            class="absolute z-50"
            :style="askAIPositionStyle"
            :visible="shouldShowAI"
            :view="view"
            :selection="selection"
          />
        </AccessControl>
      </template>
      <NoFileSelected v-else-if="isNotSelected" />
    </div>
    <div
      v-if="showResult && mode !== ProjectUIMode.Preview"
      ref="previewGutterRef"
      class="hover:bg-cta-pressed active:bg-cta-pressed row-end-last z-20 col-start-5 col-end-6 row-start-2 cursor-ew-resize bg-gray-300 transition-all duration-300"
      @mousedown.prevent
    />
    <ProjectCompileResult
      v-if="showResult"
      class="row-end-last z-10 col-start-[--editor-result-start-col] col-end-[--editor-result-end-col] row-start-2 overflow-hidden"
    />
    <div
      v-if="isDisconnected"
      class="fixed bottom-4 right-4 flex flex-col space-x-2 rounded-lg bg-black p-4 text-left text-white"
    >
      <div class="head-md">
        {{ $t('global.error.collaborationDisconnected.summary') }}
      </div>
      <div class="body-sm">{{ $t('global.error.collaborationDisconnected.detail') }}</div>
    </div>
  </main>
  <FilePreviewUnsupportedToast />
  <FileUploadModal />
  <ConfirmModal
    :visible="isAccessDenied"
    :header="t('accessDeniedModal.header')"
    :content="t('accessDeniedModal.content')"
    :onConfirm="() => $router.push('/dashboard')"
    :confirmLabel="t('accessDeniedModal.goToDashboard')"
  />
</template>

<style module>
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');
</style>

<i18n>
{
  "ko": {
    "editor": {
      "inspections": "문법 검사기"
    },
    "loading": "Loading..."
  },
  "en": {
    "editor": {
      "inspections": "Inspections"
    },
    "loading": "Loading...",
    "accessDeniedModal": {
      "header": "Access Denied",
      "content": "You do not have permission to access this document. Please contact the document owner for access rights.",
      "goToDashboard": "Go to My Dashboard"
    },
    "loadingProject": "Murfy is bringing up the project. Please wait a moment.",
    "archivedProject": {
      "summary": "Archived Project",
      "detail": "This project has been archived. To access it, please restore it from the archive."
    }
  }
}
</i18n>
