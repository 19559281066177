<script setup lang="ts">
import {
  SIDEBAR_HEADER_HEIGHT_PIXEL,
  useEditorStore,
  useErrorStore,
  useModalStore,
  useProjectFileStore,
  useProjectStore,
} from '@/stores'
import { dirname, nextDuplicatedFileName } from '@/utils/path'
import { Permission, type ProjectAsset } from '@murfy-package/api-client'
import { storeToRefs } from 'pinia'
import PanelMenu from 'primevue/panelmenu'
import { computed, nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { AccessControl, FileMoveModal, MenuButton } from '.'
import { FileTree, FileTreeNode } from '../utils/fileTree'
import EditorSideFileTreeItemFile from './EditorSideFileTreeItemFile.vue'
import EditorSideFileTreeItemFolder from './EditorSideFileTreeItemFolder.vue'
import EditorSideFileTreeItemNew from './EditorSideFileTreeItemNew.vue'

const { t } = useI18n()
const { setError } = useErrorStore()

const editorStore = useEditorStore()
const { currentFilePath } = storeToRefs(editorStore)
const { openFile } = editorStore

const { projectFileList } = storeToRefs(useProjectStore())
const { createFolder, createFile, moveFile, deleteFile, duplicateFile, deleteFolder } =
  useProjectFileStore()

const modalStore = useModalStore()

const fileTree = ref(new FileTree(projectFileList.value))
watch(projectFileList, (value) => {
  fileTree.value = new FileTree(value)
})
const fileTreeModel = computed(() => {
  const items = fileTree.value.toMenuItem().items
  return items
})
const expandedKeys = ref<Record<string, boolean>>({})
watch(currentFilePath, (value) => {
  if (value) {
    // Open the all folder where the file is located
    const path = dirname(value)
    const pathList = path.split('/')
    let currentPath = ''
    pathList.forEach((dir) => {
      currentPath += dir + '/'
      expandedKeys.value[currentPath] = true
    })
  }
})

const headerMenuItems = [
  {
    label: t('newFile'),
    action: () => {
      fileTree.value.root.add(FileTreeNode.createNew('file'))
    },
  },
  {
    label: t('uploadFile'),
    action: () => {
      modalStore.fileUploadModalVisible = true
    },
  },
  {
    separator: true,
  },
  {
    label: t('newFolder'),
    action: () => {
      fileTree.value.root.add(FileTreeNode.createNew('folder'))
    },
  },
]

const isCreating = ref(false)
const onTriggerCreate = (dirPath: string, createType: 'folder' | 'file') => {
  // 닫혀있는 폴더에 새로운 파일 또는 폴더를 생성할 경우 열어줌
  expandedKeys.value = { ...expandedKeys.value, [dirPath]: true }
  // 열린 후 생성할 파일 또는 폴더를 추가
  nextTick(() => {
    const newNode = FileTreeNode.createNew(createType)
    newNode.data.fullPath = dirPath + newNode.data.filename
    fileTree.value.get(dirPath)?.add(newNode)
  })
}
const onCreateNew = (path: string, isFolder: boolean, temporalNodeKey: string) => {
  isCreating.value = true
  if (isFolder) {
    createFolder(path)
      .catch((e) => {
        setError(e)
      })
      .finally(() => {
        isCreating.value = false
        fileTree.value.remove(temporalNodeKey)
      })
  } else {
    createFile(path)
      .catch((e) => {
        setError(e)
      })
      .finally(() => {
        isCreating.value = false
        fileTree.value.remove(temporalNodeKey)
      })
  }
}

const onDeleteFile = (path: string) => {
  deleteFile(path).catch((e) => {
    setError(e)
  })
}

const onClickFile = (path: string) => {
  openFile(path).catch((e) => {
    setError(e)
  })
}

// Moving File
const fileMoveModalVisible = ref(false)
const fileMoveModalLoading = ref(false)
const selectedFilePath = ref('')
const onTriggerMove = (targetPath: string) => {
  fileMoveModalVisible.value = true
  selectedFilePath.value = targetPath
}
const onSubmitMove = (value: FileTreeNode) => {
  const newPath = value.data.fullPath + selectedFilePath.value.split('/').pop()
  if (newPath === selectedFilePath.value) {
    fileMoveModalVisible.value = false
    return
  }
  fileMoveModalLoading.value = true
  moveFile(selectedFilePath.value, newPath)
    .catch((e) => {
      setError(e)
    })
    .finally(() => {
      fileMoveModalVisible.value = false
      fileMoveModalLoading.value = false
    })
}

// Duplicate File
const projectFilePathList = computed(() => projectFileList.value.map((file) => file.fullPath))
const onTriggerDuplicateFile = (targetPath: string) => {
  const targetNode = fileTree.value.get(targetPath)
  if (!targetNode) {
    return
  }
  const parentPath = targetPath.split('/').slice(0, -1).join('/') + '/'
  const parentNode = fileTree.value.get(parentPath)
  if (!parentNode) {
    return
  }
  const targetIndex = parentNode.children?.indexOf(targetNode)
  if (targetIndex === undefined || targetIndex === -1) {
    return
  }
  const newNode = FileTreeNode.createDuplicate(targetNode)
  parentNode.children?.splice(targetIndex + 1, 0, newNode)
  return
}
const onDuplicateFile = (targetPath: string, newPath: string, temporalNodeKey: string) => {
  duplicateFile(targetPath, newPath)
    .catch((e) => {
      setError(e)
    })
    .finally(() => {
      fileTree.value.remove(temporalNodeKey)
    })
}

const onDeleteFolder = (path: string) => {
  deleteFolder(path).catch((e) => {
    setError(e)
  })
}
</script>

<template>
  <div class="h-full">
    <div
      :style="{
        '--editor-sidebar-header-height': `${SIDEBAR_HEADER_HEIGHT_PIXEL}px`,
      }"
      class="border-color-border-primary flex h-[--editor-sidebar-header-height] flex-row items-center gap-4 border-b px-4 py-2"
    >
      <span class="head-xs text-color-text-primary w-full">{{ t('files') }}</span>
      <AccessControl :permissions="[Permission.fileCreate]">
        <MenuButton class="flex-none" icon="/icons/add.svg" :model="headerMenuItems" />
      </AccessControl>
    </div>
    <PanelMenu
      v-model:expandedKeys="expandedKeys"
      :model="fileTreeModel"
      :pt="{
        root: $style.panelMenuRoot,
        panel: $style.panelMenuPanel,
        toggleableContent: $style.panelMenuToggleableContent,
        headerContent: $style.panelMenuHeaderContent,
        menuContent: $style.panelMenuMenuContent,
        submenu: $style.panelMenuSubmenu,
      }"
    >
      <template #item="{ item }">
        <EditorSideFileTreeItemNew
          v-if="item.isForCreate"
          :folder="item.type === 'folder'"
          :parentPath="dirname(item.data.fullPath)"
          @onBlur="
            () => {
              if (!isCreating) {
                fileTree.remove(item.data.fullPath)
              }
            }
          "
          @onSave="
            (path, isFolder) => {
              onCreateNew(path, isFolder, item.data.fullPath)
            }
          "
          @keydown.enter.stop
        />
        <!--@keydown.enter.stop은 PanelMenu의 Header Keyboard Support 기능을 비활성화하기 위함-->
        <EditorSideFileTreeItemNew
          v-else-if="item.isForDuplicate"
          :folder="item.type === 'folder'"
          :defaultLabel="
            nextDuplicatedFileName(
              dirname(item.data.fullPath) + item.data.filename,
              projectFilePathList,
            )
          "
          :parentPath="dirname(item.data.fullPath)"
          :checkExist="(value) => projectFilePathList.some((filePath) => filePath === value)"
          @onBlur="
            () => {
              if (!isCreating) {
                fileTree.remove(item.data.fullPath)
              }
            }
          "
          @onSave="
            (path, _isFolder) => {
              const originalPath = dirname(path) + item.data.filename
              onDuplicateFile(originalPath, path, item.data.fullPath)
            }
          "
          @keydown.enter.stop
        />
        <EditorSideFileTreeItemFile
          v-else-if="item.data && !item.key?.endsWith('/')"
          :key="item.data.fullPath"
          :fileInfo="{
            fullPath: (item.data as ProjectAsset).fullPath,
            sizeBytes: (item.data as ProjectAsset).sizeBytes,
          }"
          @onClick="onClickFile"
          @onDelete="onDeleteFile"
          @onMove="onTriggerMove"
          @onDuplicate="onTriggerDuplicateFile"
        />
        <EditorSideFileTreeItemFolder
          v-else-if="item.data && item.key?.endsWith('/')"
          :key="item.data.fullPath + '-folder'"
          :fullPath="(item.data as ProjectAsset).fullPath"
          :open="!!expandedKeys[item.key]"
          @onCreate="(createType) => onTriggerCreate(item.data.fullPath, createType)"
          @onDelete="onDeleteFolder"
        />
      </template>
    </PanelMenu>
    <FileMoveModal
      :targetPath="selectedFilePath"
      :visible="fileMoveModalVisible"
      :isLoading="fileMoveModalLoading"
      @update:visible="
        (value) => {
          fileMoveModalVisible = value
        }
      "
      @submit="onSubmitMove"
    />
  </div>
</template>

<style module>
.panelMenuRoot {
  /* 80px is height of header */
  height: calc(100% - 80px);
  overflow: auto;
}
.panelMenuToggleableContent {
  /* 50px is height of folder */
  height: calc(100% - 50px);
}
.panelMenuMenuContent {
  height: 100%;
  padding: 0;
  border: none;
  background-color: transparent;
}
.panelMenuHeaderContent {
  border: none;
  padding: 0;
  background-color: transparent;
}
.panelMenuSubmenu {
  padding: 0;
}
</style>

<i18n>
{
  "en": {
    "files": "Files",
    "rename": "Rename",
    "delete": "Delete",
    "save": "Save",
    "newFile": "New File",
    "newFolder": "New Folder",
    "uploadFile": "Upload Files",
    "move": "Move",
    "duplicate": "Duplicate",
    "duplicateNameError": "This name already exists.",
  }
}
</i18n>
