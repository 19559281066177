<script setup lang="ts" generic="T">
import { DropdownMenu } from 'radix-vue/namespaced'
import { ref } from 'vue'

import { BaseButton } from '..'
import { IconEllipsis } from '../../atoms'

export type MenuItem<T> = {
  id: string
  label: string
  class: string
  onClick: (value?: T) => void
  value?: T
  disabled?: boolean
}

type DropdownMenuProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: MenuItem<T>[]
}

defineProps<DropdownMenuProps>()

const toggleState = ref(false)

const show = () => {
  toggleState.value = true
}
const hide = () => {
  toggleState.value = false
}

defineExpose({
  show,
  hide,
})
</script>

<template>
  <DropdownMenu.Root v-model:open="toggleState">
    <DropdownMenu.Trigger @click.stop>
      <slot name="trigger" :toggleState="toggleState">
        <BaseButton severity="secondary" variant="text">
          <template #icon>
            <IconEllipsis />
          </template>
        </BaseButton>
      </slot>
    </DropdownMenu.Trigger>
    <DropdownMenu.Portal>
      <DropdownMenu.Content
        align="start"
        class="bg-color-bg-global-primary border-color-border-primary z-50 mt-2 rounded-lg border py-2 shadow-sm"
      >
        <DropdownMenu.Item
          v-for="item in items"
          :key="item.id"
          class="body-md px-4 py-2.5"
          :class="[
            item.disabled
              ? 'text-color-text-disabled cursor-not-allowed'
              : 'text-color-text-primary hover:bg-color-bg-info-tertiary-hover active:bg-color-bg-info-tertiary-pressed cursor-pointer',
          ]"
          :disabled="item.disabled"
          @click="!item.disabled && item.onClick(item.value)"
        >
          <slot name="item" :item="item">
            {{ item.label }}
          </slot>
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Portal>
  </DropdownMenu.Root>
</template>
