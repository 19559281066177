import { defaultHighlightStyle, syntaxHighlighting } from '@codemirror/language'
import { lintGutter, linter } from '@codemirror/lint'
import { search } from '@codemirror/search'
import { Extension } from '@codemirror/state'
import {
  EditorView,
  drawSelection,
  highlightActiveLine,
  highlightActiveLineGutter,
  highlightSpecialChars,
  keymap,
  lineNumbers,
} from '@codemirror/view'
import type { App } from 'vue'
import { inject } from 'vue'
import { yUndoManagerKeymap } from 'y-codemirror.next'

import syncPdfToCode from '@/editor/extensions/syncPdfToCode'

import { latexAutocompletionExtension } from './extensions/autocomplete'
import bracketsHandlerExtension from './extensions/bracketsHandlerExtension'
import { clearLinter } from './extensions/clearDiagnostic'
import formulaTurboDynamicExtensions from './extensions/formula-turbo/formulaTurboDynamicExtensions'
import formulaTurboMakeMathEnv from './extensions/formula-turbo/formulaTurboMakeMathEnv'
import editorKeymap from './extensions/keymap'
import generalKeymap from './extensions/keymap/generalKeymap'
import { bibtex } from './extensions/languages/bibtex'
import { latex } from './extensions/languages/latex'
import { annotations } from './extensions/languages/latex/linter/helper/annotations'
import { latexLinter } from './extensions/languages/latex/linter/latex-linter'
import { markdown } from './extensions/languages/markdown'
import { mathModeCompartment } from './extensions/mathModeCompartment'
import { ConfigProps } from './properties'

const defaultTheme = EditorView.theme({
  '&': {
    fontSize: '10pt',
  },
  '.cm-line': {
    paddingLeft: '16px',
  },
  '.cm-content': {
    fontFamily: 'Hack, monospace',
    color: '#343A3E',
  },
  '.cm-gutters': {
    backgroundColor: 'transparent',
    borderRight: 'none',
  },
  '.cm-gutterElement': {
    fontFamily: 'Hack, monospace',
    color: '#868D94',
  },
  '.cm-comment': {
    cursor: 'pointer',
  },
  '.cm-comment.cm-comment-resolved': {
    backgroundColor: '#E9ECEE7F',
    textDecoration: 'underline 2px #ACB4BB7F',
  },
  '.cm-comment.cm-comment-unresolved': {
    backgroundColor: '#FFE3807F',
    textDecoration: 'underline 2px #FF991F7F',
  },
  '.cm-tooltip.cm-completionInfo': {
    padding: '0 4px',
    border: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  '.cm-tooltip-autocomplete.cm-tooltip': {
    backgroundColor: '#FFF',
    borderRadius: '2px',
    border: '1px solid #E9ECEE',
    '& > ul': {
      minWidth: 'unset',
      boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.10)',
      '& > li': {
        padding: '4px 8px',
        color: '#343A3E',
        '& .cm-completionLabel': {
          lineHeight: '20px',
          fontSize: '14px',
        },
        '&:hover': {
          background: '#F4FBFE',
          color: '#DE350B',
        },
        '&[aria-selected]': {
          borderRadius: '0px',
          background: '#F4FBFE',
        },
      },
    },
  },
})

export const DEFAULT_CONFIG: Readonly<Partial<ConfigProps>> = Object.freeze({
  extensions: [
    clearLinter(),
    EditorView.lineWrapping,
    defaultTheme,
    syntaxHighlighting(defaultHighlightStyle),
    lineNumbers(),
    lintGutter(), // (lint) 문제를 발견한 줄의 옆에 표시 여부 설정
    highlightActiveLineGutter(),
    highlightActiveLine(),
    highlightSpecialChars(),
    drawSelection(),
    search(),
    keymap.of([...yUndoManagerKeymap]),
  ],
})

// Exported extensions by language
const defaultExtensions = DEFAULT_CONFIG.extensions ?? []

export const getExtensionsByLanguage: { [language: string]: Extension[] } = {
  latex: [
    latex(),
    linter(latexLinter),
    annotations(),
    syncPdfToCode,
    mathModeCompartment.of([]),
    editorKeymap,
    formulaTurboDynamicExtensions,
    formulaTurboMakeMathEnv,
    latexAutocompletionExtension,
    bracketsHandlerExtension,
    defaultExtensions,
  ],
  bibtex: [bibtex(), generalKeymap, defaultExtensions],
  markdown: [markdown(), generalKeymap, defaultExtensions],
  default: [generalKeymap, defaultExtensions],
}

const CONFIG_SYMBOL = Symbol('vue-codemirror-global-config')
export const injectGlobalConfig = (app: App, config?: ConfigProps) => {
  app.provide(CONFIG_SYMBOL, config)
}

export const useGlobalConfig = () => inject<ConfigProps>(CONFIG_SYMBOL, {} as ConfigProps)
