<script setup lang="ts">
import { Activity, ExtraActivity } from '@/views/EditorViewActivity'
import { ref } from 'vue'

import EditorSideComment from './EditorSideComment.vue'
import EditorSideFileTree from './EditorSideFileTree.vue'
import EditorSideFormulaTurbo from './EditorSideFormulaTurbo.vue'
import EditorSideHelp from './EditorSideHelp.vue'
import EditorSideShare from './EditorSideShare.vue'

const editorSideRef = ref<HTMLElement | null>(null)

defineProps<{
  activeActivity?: Activity | ExtraActivity
}>()
</script>

<template>
  <div
    ref="editorSideRef"
    class="bg-color-bg-global-secondary relative flex h-full w-full flex-col overflow-visible"
  >
    <EditorSideFileTree v-show="activeActivity === Activity.File" />
    <!--project 정보를 읽어와야 해서 v-show 로 처리 하지 않고 v-if 로 합니다. -->
    <EditorSideShare v-if="activeActivity === Activity.Share" />
    <EditorSideComment v-if="activeActivity === Activity.Comment" />
    <EditorSideFormulaTurbo v-show="activeActivity === Activity.FormulaTurbo" />
    <EditorSideHelp v-show="activeActivity === ExtraActivity.Help" />
  </div>
</template>

<i18n>
{
  "en": {
    "assets": "File"
  }
}
</i18n>
