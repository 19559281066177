/*
 * All i18n resources specified in the plugin `include` option can be loaded
 * at once using the import syntax
 */
import messages from '@intlify/unplugin-vue-i18n/messages'
import { createAPIClient } from '@murfy-package/api-client'
import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'
import { createApp } from 'vue'
import vue3GoogleLogin from 'vue3-google-login'
import { createI18n } from 'vue-i18n'

import App from './App.vue'
import './assets/styles/index.css'
import globalLocale from './locales'
import router from './router'

const app = createApp(App)

Sentry.init({
  app,
  environment: import.meta.env.MODE, // One of dev_local, development, staging, production
  dsn: import.meta.env.VITE_SENTRY_DSN, // Can be obtained from Sentry.io
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^http:\/\/localhost/,
    /^https:\/\/app.dev.murfy.com/,
    /^https:\/\/app.staging.murfy.com/,
    /^https:\/\/app.murfy.com/,
  ],
  // Session Replay
  replaysSessionSampleRate: import.meta.env.MODE === 'development' ? 0 : 0.1, // replay 한도가 50개 밖에 안되기 때문에 development 모드에서는 0으로 설정
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.use(createPinia())
app.use(router)
const i18nInstance = createI18n({
  legacy: false,
  locale: 'en',
  messages: {
    en: {
      ...globalLocale.en,
      ...messages?.en,
    },
  },
})
export const i18n = i18nInstance.global
app.use(i18nInstance)
app.use(vue3GoogleLogin, {
  clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
})
app.use(createAPIClient({ baseURL: import.meta.env.VITE_APP_API_BASE_URL }))
app.use(PrimeVue)
app.use(ToastService)
app.directive('tooltip', Tooltip)
app.mount('#app')

document.title = import.meta.env.VITE_TITLE
