<script setup lang="ts">
import { usePermission } from '@/stores'
import { Permission, ProjectInfoSummary, Role } from '@murfy-package/api-client'
import {
  BaseBadge,
  BaseButton,
  DataTable,
  DropdownMenu,
  MenuItem,
  type TableBodyItem,
  type TableHeadItem,
} from '@murfy-package/murds'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import TimeDisplay from './TimeDisplay.vue'

const { t } = useI18n()

const props = defineProps<{
  projects: ProjectInfoSummary[]
}>()

const emit = defineEmits<{
  edit: [project: ProjectInfoSummary]
  download: [project: ProjectInfoSummary]
  open: [project: ProjectInfoSummary]
  archiveSelected: [projects: ProjectInfoSummary[]]
  leaveSelected: [projects: ProjectInfoSummary[]]
  restoreSelected: [projects: ProjectInfoSummary[]]
}>()

const headItems: TableHeadItem[] = [
  {
    key: 'projectName',
    label: t('projectName'),
    width: 'auto',
    align: 'start',
    sortable: true,
  },
  {
    key: 'lastRevised',
    label: t('lastRevised'),
    width: '140px',
    align: 'start',
    sortable: true,
  },
  {
    key: 'role',
    label: t('role'),
    width: '140px',
    align: 'start',
  },
  {
    key: 'actions',
    width: '20px',
    align: 'center',
  },
]
const bodyItems = computed<TableBodyItem[]>(() =>
  props.projects.map((project) => ({
    key: project.id,
    projectName: project.name,
    lastRevised: project.updatedAt,
    role: project.role,
    actions: getProjectActions(project),
    disabled: project.archived,
  })),
)

const { checkPermissionByRole } = usePermission()
const getProjectActions = (project: ProjectInfoSummary) => {
  const actions = []
  if (project.role && checkPermissionByRole(project.role, Permission.projectUpdate)) {
    actions.push({
      id: 'edit',
      label: t('menu.edit'),
      onClick: () => {
        emit('edit', project)
      },
      disabled: project.archived,
    })
  }
  actions.push({
    id: 'download',
    label: t('menu.download'),
    onClick: () => {
      emit('download', project)
    },
  })
  if (project.role !== Role.owner) {
    actions.push({
      id: 'leave',
      label: t('menu.leave'),
      class: 'text-color-text-danger',
      onClick: () => {
        emit('leaveSelected', [project])
      },
    })
  } else if (project.archived) {
    actions.push({
      id: 'restore',
      label: t('menu.restore'),
      class: 'text-color-text-brand',
      onClick: () => {
        emit('restoreSelected', [project])
      },
    })
  } else {
    actions.push({
      id: 'archive',
      label: t('menu.archive'),
      class: 'text-color-text-danger',
      onClick: () => {
        emit('archiveSelected', [project])
      },
    })
  }
  return actions
}
const getProjectById = (projectId: string) =>
  props.projects.find((project) => project.id === projectId)
const handleClickRow = (item: TableBodyItem) => {
  if (item.disabled || !item.key) return
  const project = getProjectById(item.key)
  if (!project) return
  emit('open', project)
}

// handle Selected Projects
const selectedProjectIds = ref<string[]>([])
const canRestore = (project: ProjectInfoSummary) => project.archived && project.role === Role.owner
const canArchive = (project: ProjectInfoSummary) => !project.archived && project.role === Role.owner
const canLeave = (project: ProjectInfoSummary) => project.role !== Role.owner
const showRestoreButton = computed(() =>
  selectedProjectIds.value.some((projectId) => {
    const project = getProjectById(projectId)
    return project && canRestore(project)
  }),
)
const showArchiveButton = computed(() =>
  selectedProjectIds.value.some((projectId) => {
    const project = getProjectById(projectId)
    return project && canArchive(project)
  }),
)
const showLeaveButton = computed(() =>
  selectedProjectIds.value.some((projectId) => {
    const project = getProjectById(projectId)
    return project && canLeave(project)
  }),
)
const handleRestoreSelected = () => {
  const restoreTargets = props.projects.filter(
    (project) => selectedProjectIds.value.includes(project.id) && canRestore(project),
  )
  emit('restoreSelected', restoreTargets)
}

const handleArchiveSelected = () => {
  const archiveTargets = props.projects.filter(
    (project) => selectedProjectIds.value.includes(project.id) && canArchive(project),
  )
  emit('archiveSelected', archiveTargets)
}

const handleLeaveSelected = () => {
  const leaveTargets = props.projects.filter(
    (project) => selectedProjectIds.value.includes(project.id) && canLeave(project),
  )
  emit('leaveSelected', leaveTargets)
}
</script>

<template>
  <div class="flex h-full min-h-[200px] w-full flex-col gap-5">
    <div class="flex h-9 flex-none items-center justify-between gap-5">
      <i18nT class="text-color-text-primary head-md" keypath="totalNumProject" tag="span">
        <span class="text-color-text-brand">{{ props.projects.length }}</span>
      </i18nT>
      <div class="flex gap-2">
        <BaseButton v-if="showRestoreButton" severity="info" @click="handleRestoreSelected()">
          {{ t('restore') }}
        </BaseButton>
        <BaseButton v-if="showArchiveButton" severity="danger" @click="handleArchiveSelected()">
          {{ t('archive') }}
        </BaseButton>
        <BaseButton v-if="showLeaveButton" severity="danger" @click="handleLeaveSelected()">
          {{ t('leave') }}
        </BaseButton>
      </div>
    </div>
    <div class="h-full overflow-auto">
      <DataTable
        v-model:selectModel="selectedProjectIds"
        :headItems="headItems"
        :bodyItems="bodyItems"
        size="sm"
        selectable
        clickable
        :loadingMessage="t('loading')"
        defaultSortKey="lastRevised"
        @clickRow="handleClickRow"
      >
        <template #projectName-body="{ item }">
          <span class="truncate" :title="item.projectName as string">{{ item.projectName }}</span>
        </template>
        <template #lastRevised-body="{ item }">
          <TimeDisplay :datetime="item.lastRevised as string" />
        </template>
        <template #role-body="{ item }">
          <BaseBadge
            :label="item.role as Role"
            :color="
              item.role === Role.owner ? 'blue' : item.role === Role.editor ? 'yellow' : 'gray'
            "
          />
        </template>
        <template #actions-body="{ item }">
          <DropdownMenu :items="item.actions as MenuItem<ProjectInfoSummary>[]">
            <template #item="{ item: dropdownMenuItem }">
              <template v-if="!dropdownMenuItem.class">{{ dropdownMenuItem.label }}</template>
              <span v-else :class="dropdownMenuItem.class">
                {{ dropdownMenuItem.label }}
              </span>
            </template>
          </DropdownMenu>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "loading": "Please wait a moment.",
    "projectName": "Project Name",
    "lastRevised": "Last Revised",
    "role": "Role",
    "menu": {
      "edit": "Edit",
      "download": "Download",
      "leave": "Leave",
      "restore": "Restore",
      "archive": "Archive",
    },
    "totalNumProject": "Total of {0} Projects",
    "restore": "Restore",
    "archive": "Archive",
    "leave": "Leave",
  },
}
</i18n>
