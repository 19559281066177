<script setup lang="ts">
import { usePDFViewerLoadState } from '@/components/PDFViewerLoadState'
import PDFViewerPage from '@/components/PDFViewerPage.vue'
import { usePDFViewerPageScroll } from '@/components/PDFViewerPageScroll'
import PDFViewerThumbnail from '@/components/PDFViewerThumbnail.vue'
import PDFViewerToolbar from '@/components/PDFViewerToolbar.vue'
import { usePDFViewerZoom } from '@/components/PDFViewerZoom'
import SharedErrorPanel from '@/components/SharedErrorPanel.vue'
import { useProjectStore, useSyncTexStore } from '@/stores'
import { BaseProgressSpinner } from '@murfy-package/ui'
import { GlobalWorkerOptions } from 'pdfjs-dist'
import Worker from 'pdfjs-dist/build/pdf.worker?worker'
import { storeToRefs } from 'pinia'
import { computed, ref, toRefs, watch } from 'vue'
// https://github.com/vuejs/rfcs/blob/master/active-rfcs/0040-script-setup.md#using-directives
import { dragscroll as vDragscroll } from 'vue-dragscroll'

const props = withDefaults(
  defineProps<{
    src: string
    filename?: string
  }>(),
  {
    src: () => '',
    filename: () => '',
  },
)

const { src } = toRefs(props)

GlobalWorkerOptions.workerPort = new Worker()

const error = ref<Error | null>(null)
const hasError = computed(() => !!error.value)

// 로드 관련 로직
const {
  showLoadingState,
  setRenderViewport,
  showLoadingProgress,
  loadingProgress,
  maxLoadingProgress,
  maxWidth,
  maxHeight,
  pages,
  reload,
  load,
} = usePDFViewerLoadState()

watch(
  src,
  (p, old) => {
    if (p === old || !p) {
      return
    }
    try {
      if (old) {
        reload(p)
      } else {
        load(p)
      }
    } catch (e) {
      error.value = e as Error
    }
  },
  {
    immediate: true,
  },
)

const printArea = ref<HTMLElement | null>(null)
// 줌 관련 로직
const { selectedZoomValue, increaseZoom, decreaseZoom, currentZoom, containerHeight } =
  usePDFViewerZoom(printArea, maxWidth, maxHeight)

const changeZoomValue = (value: string) => {
  selectedZoomValue.value = value
}

const isDragging = ref(false)
const startDragging = () => {
  isDragging.value = true
}
const endDragging = () => {
  isDragging.value = false
}

const {
  pageRefs,
  increaseCurrentPageIndexNumber,
  decreaseCurrentPageIndexNumber,
  currentPageIndexNumber,
} = usePDFViewerPageScroll(printArea, pages)

const dragHandleStyle = computed(() => {
  if (pageRefs.value.length < 1) {
    return {}
  }
  const lastElement = pageRefs.value[pageRefs.value.length - 1]
  const t = lastElement.$el.getBoundingClientRect().height
  let number = containerHeight.value - t
  if (number < 0) {
    number = 0
  }
  return {
    '--page-list-bottom-padding': `${number}px`,
  }
})

const showNavigation = ref(false)
const navigationToggle = () => {
  showNavigation.value = !showNavigation.value
}

// PDF 다운로드 기능
const download = () => {
  const link = document.createElement('a')
  link.href = src.value
  link.download = props.filename || 'download.pdf'
  link.click()
}

const projectStore = useProjectStore()
const { projectId } = storeToRefs(projectStore)

const syncTexStore = useSyncTexStore()
const { syncCodeToPdfResults } = storeToRefs(syncTexStore)

// 페이지로 이동하는 함수
const navigateToPage = (pageIndexNumber: number, y: number) => {
  const pageComponent = pageRefs.value[pageIndexNumber]
  if (printArea.value) {
    // 페이지 요소의 위치를 기준으로 스크롤 위치 계산
    const pageElement = pageComponent.$el as HTMLElement
    const pageRect = pageElement.getBoundingClientRect()
    const scrollTop = y + pageRect.height * pageIndexNumber

    printArea.value.scrollTo({
      top: scrollTop,
      behavior: 'instant',
    })
  }
}

// watch 콜백에서 페이지 이동 추가
watch(syncCodeToPdfResults, (newVal) => {
  if (newVal.length > 0) {
    const { page, v } = newVal[0]
    navigateToPage(page - 1, v) // 페이지 번호는 0부터 시작한다고 가정
  }
})

// 더블 클릭 이벤트 핸들러
const handleDoubleClickPage = (payload: { pageNumber: number; x: number; y: number }) => {
  if (!projectId.value) {
    return
  }
  syncTexStore.syncPdfToCode(projectId.value, payload.pageNumber, payload.x, payload.y)
}
</script>

<template>
  <div class="grid-rows-fr grid grid-cols-12 overflow-hidden">
    <div
      ref="printArea"
      v-dragscroll
      class="page-container col-span-12 col-start-1 row-span-1 row-start-2 flex flex-col items-start justify-start gap-5 overflow-auto bg-gray-500 pb-[--page-list-bottom-padding] dark:bg-gray-500 print:break-inside-auto"
      :class="[
        isDragging ? 'cursor-grabbing' : 'cursor-grab',
        showLoadingState
          ? 'pointer-events-none absolute h-0 w-0 opacity-0'
          : 'pointer-events-auto relative h-auto w-auto opacity-100',
      ]"
      :style="dragHandleStyle"
      @dragscrollstart="startDragging"
      @dragscrollend="endDragging"
    >
      <PDFViewerPage
        v-for="page in pages"
        :key="page.key"
        ref="pageRefs"
        :zoom="currentZoom"
        :page="page.page"
        :pageIndexNumber="page.pageIndexNumber"
        :selectionRects="syncCodeToPdfResults"
        class="print:fit-object m-auto print:w-full print:break-after-page"
        :data-page-key="page.key"
        @rendered="setRenderViewport(page.key, $event)"
        @pageDoubleClick="handleDoubleClickPage"
      />
    </div>
    <SharedErrorPanel
      v-if="hasError"
      class="z-40 col-span-12 col-start-1 row-span-2 row-start-1"
      :error="error"
    />
    <div
      v-if="showLoadingState"
      class="relative z-30 col-span-12 col-start-1 row-span-2 row-start-1 flex flex-col items-center justify-center gap-2 bg-gray-200 p-2 text-gray-900"
    >
      <BaseProgressSpinner />
      <p :class="showLoadingProgress ? 'opacity-100' : 'opacity-0'">
        {{ loadingProgress }} / {{ maxLoadingProgress }}
      </p>
    </div>
    <template v-else>
      <PDFViewerToolbar
        v-model:current-page-index-number="currentPageIndexNumber"
        class="z-20 col-span-12 col-start-1 row-span-1 row-start-1 h-10"
        :isToolbarDisabled="showLoadingState"
        :lastPageNumber="pages.length"
        :zoomValue="selectedZoomValue"
        @movePrev="decreaseCurrentPageIndexNumber"
        @moveNext="increaseCurrentPageIndexNumber"
        @download="download"
        @toggleNavigation="navigationToggle"
        @decreaseZoom="decreaseZoom"
        @increaseZoom="increaseZoom"
        @selectZoom="changeZoomValue"
      />
      <div
        class="z-40 col-start-1 row-span-1 row-start-2 flex flex-col items-center gap-2 bg-gray-600 transition-all print:hidden"
        :class="
          showNavigation
            ? 'col-end-4 w-auto overflow-auto p-2'
            : 'col-end-1 w-0 overflow-hidden p-0'
        "
      >
        <PDFViewerThumbnail
          v-for="page in pages"
          :key="page.key"
          v-model:current-page-index-number="currentPageIndexNumber"
          class="border-2 border-transparent transition-colors hover:border-gray-100"
          :page="page.page"
          :pageIndexNumber="page.pageIndexNumber"
        />
      </div>
    </template>
  </div>
</template>

<i18n>
{
  "ko": {
    "noPages": "페이지가 없습니다."
  },
  "en": {
    "noPages": "Empty PDF."
  }
}
</i18n>

<style scoped>
.page-container::-webkit-scrollbar {
  height: 0;
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
</style>
