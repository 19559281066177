import { useAPIClient } from '@murfy-package/api-client'
import { LatexCompileRuleCode, LatexLogParser } from '@murfy-package/latex-log-parser'
import type { LogEntry } from '@murfy-package/latex-log-parser/src/parser'
import * as Sentry from '@sentry/browser'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { computed } from 'vue'

import { useEditorStore, useErrorStore, useProjectStore, useProjectUIStore } from '.'

export const useProjectCompileStore = defineStore('project-compile', () => {
  const apiClient = useAPIClient()
  const errorStore = useErrorStore()
  const projectStore = useProjectStore()
  const editorStore = useEditorStore()
  const projectUIStore = useProjectUIStore()
  /**
   * base64로 인코딩된 pdf 데이터입니다.
   */
  const pdfPreview = ref('')

  /**
   * preview 로그 문자열입니다.
   */
  const previewLog = ref('')

  /**
   * 컴파일 로그를 파싱한 결과입니다.
   */
  const parsedCompileLog = computed(() => {
    let logEntries: LogEntry[] = []
    if (!previewLog.value.startsWith('This is pdfTeX')) {
      // pdfTeX 로그가 아닌 경우, 우리의 커스텀 에러 메시지임. 파싱하지 않음.
      if (previewLog.value === 'PDF rendering failed.') {
        return [
          {
            code: 'PDF_RENDERING_FAILED',
            level: 'error',
            fileName: '',
            message: 'PDF rendering failed.',
            rawLog: previewLog.value,
          },
        ]
      } else if (previewLog.value === 'PDF preview compilation timeout.') {
        return [
          {
            code: 'PDF_RENDERING_TIMEOUT',
            level: 'error',
            fileName: '',
            message: 'PDF preview compilation timeout.',
            rawLog: previewLog.value,
          },
        ]
      }
    } else {
      try {
        logEntries = new LatexLogParser(previewLog.value).parse()
      } catch (error) {
        if (error instanceof Error) {
          errorStore.setError(error)
        }
        logEntries = []
      }
    }
    logEntries = logEntries.map((entry) => {
      // 서버에서 보내주는 파일 경로는 로컬 캐시 경로를 포함하고 있음. 이를 제거하여 사용자에게 보여줌.
      // 프론트엔드에서 사용하는 파일 경로는 ./로 시작하지 않음. 이를 제거하여 사용자에게 보여줌.
      const filteredFileName = entry.fileName
        .replace(/\/tmp\/local_cache\/temp_[a-zA-Z0-9]+\/project\//, '')
        .replace(/^\.\//, '')
      return { ...entry, fileName: filteredFileName }
    })
    // code가 NoPdfError인 경우 해당 로그를 가장 앞으로 가져옴.
    const noPdfErrorIndex = logEntries.findIndex(
      (entry) => entry.code === LatexCompileRuleCode.NoPdfError,
    )
    if (noPdfErrorIndex !== -1) {
      const noPdfError = logEntries.splice(noPdfErrorIndex, 1)[0]
      logEntries.unshift(noPdfError)
    }
    return logEntries
  })

  /**
   * pdf 렌더링이 실패했는지 여부를 가리킵니다.
   */
  const isRenderFailed = ref(false)

  /**
   * pdf를 렌더링 중인지 여부를 가리킵니다.
   */
  const isRenderingPdf = ref(false)

  const renderPdf = async () => {
    isRenderingPdf.value = true
    if (isRenderFailed.value) {
      isRenderFailed.value = false
    }
    if (projectUIStore.compileLogVisible) {
      projectUIStore.compileLogVisible = false
    }
    try {
      if (!projectStore.projectId) {
        // 일어나면 안되는 에러. Sentry에 로깅
        const newError = new Error('Project ID is not found')
        Sentry.captureException(newError)
        throw newError
      }
      if (!editorStore.currentFilePath) {
        // 유저 실수로 인한 예외상황. 파일을 선택하지 않은 상태에서 랜더링 시도함.
        throw new Error('No selected file')
      }
      const pdfResult = await apiClient.project.exportToPdf(
        projectStore.projectId,
        editorStore.currentFilePath,
      )
      pdfPreview.value = `data:application/pdf;base64,${pdfResult.encodedPdf}`
      previewLog.value = pdfResult.compileLog
      isRenderFailed.value = false
      projectUIStore.compileLogVisible = false
    } catch (error) {
      pdfPreview.value = ''
      // FIXME: i18n 적용 필요
      /* @ts-expect-error need to type error in future */
      previewLog.value = error.detail?.[0]?.metadata?.log || 'PDF rendering failed.'
      isRenderFailed.value = true
      projectUIStore.compileLogVisible = true
    }
    isRenderingPdf.value = false
    editorStore.isModifiedAfterRender = false
  }

  const $reset = () => {
    pdfPreview.value = ''
    previewLog.value = ''
  }
  return {
    pdfPreview,
    previewLog,
    parsedCompileLog,
    isRenderFailed,
    isRenderingPdf,
    renderPdf,
    $reset,
  }
})
