import {
  ProjectUILayout,
  useProjectCompileStore,
  useProjectUIStore,
  useSyncTexStore,
} from '@/stores'
import { EditorView } from '@codemirror/view'
import { storeToRefs } from 'pinia'

export const syncCodeToPdf = (projectId: string, textPath: string | null) => {
  const syncTexStore = useSyncTexStore()
  const { layout } = storeToRefs(useProjectUIStore())
  const { pdfPreview } = storeToRefs(useProjectCompileStore())

  return EditorView.domEventHandlers({
    dblclick(_event, view) {
      if (layout.value === ProjectUILayout.Single || pdfPreview.value === '') return
      const state = view.state
      const selection = state.selection

      if (!selection || selection.ranges.length === 0) return

      const selectedText = state.doc.sliceString(selection.main.from, selection.main.to)
      if (selectedText && textPath) {
        const fromPos = view.coordsAtPos(selection.main.from)

        if (fromPos) {
          const line = state.doc.lineAt(selection.main.from)
          const row = line.number
          const col = line.text.length

          syncTexStore.syncCodeToPdf(projectId, textPath, row, col).then()
        }
      }
    },
  })
}
